import React, { useState, createContext } from 'react';

import { ListItem } from '../types/Lookup';

export const AllocationContext = createContext({

});



interface Props {
    children: React.ReactNode
}


export const AllocationProvider: React.FC<Props> = ({ children }) => {
    const [listItems, setListItems] = useState<ListItem[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>({ list: true, items: true });
    const [firstLoad, setFirstLoad] = useState<Boolean>(true);
    const [refreshAllocation, setRefreshAllocation] = useState<boolean>(false);
    const [tableType, setTableType] = useState<'default' | 'compliance'>('default')

    const exportValue = {
        listItems,
        setListItems,
        items,
        setItems,
        selectedItem,
        setSelectedItem,
        isLoading,
        setIsLoading,
        firstLoad,
        setFirstLoad,
        refreshAllocation,
        setRefreshAllocation,
        tableType,
        setTableType
    }

    return (
        <AllocationContext.Provider value={exportValue}>
            {children}
        </AllocationContext.Provider>
    )

}


export const useAllocationContext = () => {
    const context = React.useContext(AllocationContext);
    if (context === undefined) {
        throw new Error('useAllocationContext must be used within app provider')
    }
    return context
}



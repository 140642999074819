import React from 'react'

import { Label } from '@mit/hui'

import { Row, Col } from 'react-bootstrap'

import { ActionType } from '../../types'

interface Props {
    label: string
    inputComponent: any
    type: ActionType
    labelPlacement?: 'top' | 'side'
    fullWidth?: boolean
    subLabel?: string | any
    value?: any
    required?: boolean
    error?: boolean
}

export const FormRow: React.FC<Props> = ({ label, labelPlacement, inputComponent, required, error, value, type, subLabel, fullWidth }) => {
    const checkValidValue = () => {
        if (!value) return false
        if (Array.isArray(value) && value.length === 0) return false

        return true
    }

    const getDisplayText = () => {
        if (!value) return ''
        if (Array.isArray(value)) return value.map((item: any) => item.name).join(', ')
        if (value.hasOwnProperty('name')) return value.name

        return value
    }

    return (
        <Row className='mb-3'>
            <Col md={12} lg={labelPlacement === 'top' ? 12 : 4}>
                <Label text={label} />
                {subLabel && <p className='sub-label'>{subLabel}</p>}
            </Col>
            <Col md={12} lg={fullWidth ? 12 : 6}>
                {type !== 'DELETE' && type !== 'VIEW' ? (
                    <div className={'form-row-component-container'}>
                        {inputComponent}
                        {required && !checkValidValue() && <span className='text-required-dot-1'></span>}
                        {error && <div className='shake-horizontal required-validation'>{value ? 'Invalid' : 'Required'}</div>}
                        {error && <div className='shake-horizontal required-validation'>{error}</div>}
                    </div>
                ) : (
                    <h6>{getDisplayText()}</h6>
                )}
            </Col>
        </Row>
    )
}

import React, { useEffect, useState } from 'react'
import { Toggle, Label } from '@mit/hui'
import { Dropdown } from '../../input'
import cloneDeep from 'clone-deep'

interface Props {
    context: any
    filteredItems: any[]
    isLoading: boolean
    onFilter: (items: any) => void
}

const filterOptions = [
    {
        id: 'waiver',
        name: 'Invalid Waiver',
    },
    {
        id: 'training',
        name: 'Invalid Training',
    },
    {
        id: 'medical',
        name: 'Invalid Medical',
    },
    {
        id: 'attestation',
        name: 'Invalid Attestation',
    },
    {
        id: 'has_emergency_override',
        name: 'Has Emergency Override',
    },
    {
        id: 'has_resident_hold',
        name: 'Has Resident Hold',
    },
]

const initialState = {
    toggle: { choice: 'Negative' },
    filters: [],
}

export const AllocationTableToggle: React.FC<Props> = ({ context, filteredItems, isLoading, onFilter }) => {
    const [state, setState] = useState<any>(cloneDeep(initialState))
    const { setTableType, items } = context

    const handleFilterChange = (value: any) => {
        setState((prev: any) => ({ ...prev, filters: value ? value : [] }))
    }

    const filterItems = () => {
        if (state.toggle.choice === 'Positive') {
            let itms = filteredItems || items

            itms = itms.filter((item: any) => {
                let valid = []

                if (state.filters.length) {
                    for (const filter of state.filters) {
                        if (filter.id === 'has_emergency_override' || filter.id === 'has_resident_hold') {
                            if (item[filter.id]) valid.push(true)
                        } else if (item.requirement_status[filter.id] === false) {
                            valid.push(true)
                            // break;
                        }
                    }
                    return valid.length
                } else {
                    return true
                    // return item.requirement_status.attestation && item.requirement_status.medical && item.requirement_status.training && item.requirement_status.waiver
                }
                // return valid.length == state.filters.length;
            })
            onFilter(itms)
            console.log(itms)
        } else {
            onFilter(items)
        }
    }

    // useEffect(() => {
    //     // debugger;
    //     setState(cloneDeep(initialState));
    // }, [selectedItem])

    useEffect(() => {
        setTableType(state.toggle.choice === 'Positive' ? 'compliance' : 'default')
        //eslint-disable-next-line
    }, [state.toggle.choice])

    useEffect(() => {
        filterItems()
        //eslint-disable-next-line
    }, [state, filteredItems])

    console.log('toggle state', state)

    if (isLoading) return <span></span>

    return (
        <div className='d-flex align-items-center allocation-toggle'>
            <Toggle choice={state.toggle.choice} onStateChanged={(choice: any) => setState((prev: any) => ({ ...prev, toggle: choice }))} />
            &nbsp;&nbsp;
            <Label text='Compliance Details' />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* {state.toggle.choice === 'Positive' && state.filters.map((filter: any) => (
                <>
                    <Checkbox
                        label={filter.label}
                        name={filter.id}
                        checked={filter.checked}
                        onClick={(checked: boolean) => handleFilterChange(checked, filter.id)}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
            ))} */}
            {state.toggle.choice === 'Positive' && <Dropdown isMulti options={filterOptions} value={state.filters} onChange={handleFilterChange} />}
        </div>
    )
}

import React, { useState } from 'react'
import { AdvancedTable, IAdvancedTableColumn } from '@mit/hui'
// import { getDateTimeString, getFormattedDateString } from '../../common/Util';
// import { useFormContext } from '../../../context'
// import { SponsoredAccountFilter } from './';
import { getDateTimeString } from '../../../common/Util'
import { useEffect } from 'react'
import { CardSwipesController } from '../../../api/controller'

interface Props {
    id: string
}

export const CardSwipesTable: React.FC<Props> = ({ id }) => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const controller = new CardSwipesController()

        setIsLoading(true)
        controller
            .getSwipes(id)
            .then((data) => {
                setItems(data)
                // setCanViewCardSwipes(true);
                setIsLoading(false)
            })
            .catch((error) => {
                setItems([])
                setIsLoading(false)
                // setCanViewCardSwipes(true);
            })
    }, [id])

    let columns: IAdvancedTableColumn[] = [
        {
            id: 'name',
            name: 'Location',
        },
        {
            id: 'event_timestamp_local',
            name: 'Swipe On',
            formatter: (item: any) => {
                return getDateTimeString(item.event_timestamp_local)
            },
        },
        {
            id: 'event_type',
            name: 'Status',
        },
    ]

    // const today = new Date();

    // const exportOptions = {
    //     button: {
    //         type: ButtonType.Icon,
    //         icon: 'download',
    //         onClick: () => alert('Download Csv'),
    //         text: '',
    //         state: ButtonState.Enabled
    //     },
    //     columns: csvExportCol,
    //     items: [],
    //     fileName: `${selectedItem.name} ${today.toISOString()}`,
    //     customHeader: null

    // };

    // useEffect(() => {
    //     setFilteredItems(null);
    //     //eslint-disable-next-line
    // }, [selectedItem])

    return (
        <>
            <AdvancedTable
                columns={columns}
                items={items}
                isLoading={isLoading}
                noDataText='No Card Swipes'
                showNoData={true}
                showFilterButton={false}
                showExportButton={false}
            />

            {/* <SponsoredAccountFilter
                show={showFilter}
                items={items}
                onClose={() => setShowFilter(false)}
                onFilter={(items: any) => setFilteredItems(items)}
                selectedItem={selectedItem}
            /> */}
        </>
    )
}

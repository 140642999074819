import React from 'react'

import { ActionType } from '../../../../types/Table';

import { FormRow } from '../../';
import { Dropdown, Input, PeopleSearch } from '../../../input';

interface Props {
    data: any
    formType: ActionType
    errors: any
    onChangeInput: (value: any, key: string) => void
    lookups: any
}

export const SponsorInformation: React.FC<Props> = ({ data, errors, formType, onChangeInput, lookups }) => {
    const { departments }: any = lookups;

    return (
        <>
            <h6>Sponsor Information</h6>
            <FormRow
                inputComponent={
                    <PeopleSearch
                        value={data.sponsor}
                        onChange={(value: any) => onChangeInput(value, 'sponsor')} />
                }
                label='Sponsor'
                type={formType}
                value={data.sponsor}
                error={errors.sponsor}
                required
            />
            <FormRow
                inputComponent={
                    <Dropdown
                        options={departments}
                        value={data.department}
                        onChange={(value: any) => onChangeInput(value, 'department')} />
                }
                label='Department'
                type={formType}
                value={data.department}
                error={errors.department}
                required
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.officeAddress}
                        onChange={(value: any) => onChangeInput(value, 'officeAddress')}
                        type='string' />
                }
                label='Office Address'
                subLabel='(bldg-room)'
                type={formType}
                value={data.officeAddress}
                error={errors.officeAddress}
                required
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.costObject}
                        onChange={(value: any) => onChangeInput(value, 'costObject')}
                        type='number'
                        maxLength={7} />
                }
                label='Cost Object'
                type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                value={data.costObject}
                error={errors.costObject}
                required
            />
        </>
    );
}
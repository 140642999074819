import React from "react";
import Tooltip from "../../Tooltip";

interface Props {
    item: any;
}

export const AllocationStatusFlags: React.FC<Props> = ({ item }) => {
    // const getText = (prop: string) => {
    //     if (item[prop]) {
    //         return 'Completed'
    //     }
    //     return 'Not Completed'
    // }

    // const getStatusClass = (prop: string) => {
    //     if (item[prop]) {
    //         return 'fa-check icon icon-completed'
    //     }
    //     return 'fa-exclamation-triangle icon icon-not-completed'
    // }

    return (
        <div>
            {/* <Tooltip text={`Requirements ${getText('is_requirements_complete')}`}>
                <i className={`fas  ${getStatusClass('is_requirements_complete')}`}></i>
            </Tooltip>
            <Tooltip text={`Attestation ${getText('is_daily_attestation_complete')}`}>
                <i className={`fas ml-1 ${getStatusClass('is_daily_attestation_complete')}`}></i>
            </Tooltip> */}
            {item.has_emergency_override && (
                <Tooltip text={`Emergency Override`}>
                    <i className={`fas ml-1 fa-first-aid icon-pending`}></i>
                </Tooltip>
            )}
            {item.on_holds.map((itm, idx) => (
                <Tooltip text={itm.name + ` Hold`} key={'hold' + idx}>
                    <i className={'fas ml-1 fa-' + itm.icon + ' icon-pending'}></i>
                </Tooltip>
            ))}
           
           
        </div>
    );
};

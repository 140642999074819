import React, { useState } from 'react';
import Popover from '../../PopoverFilter';
import Tooltip from '../../Tooltip';

import { useFormContext } from '../../../context';

export interface Action {
    name: string;
    onClick: () => void;
    icon?: string;
    auth_role?: string;
    class?: string;
}

interface Props {
    icon: string;
    item: any;
    className?: string;
}

export const AllocationMoreActions: React.FC<Props> = ({ icon, item }) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const { setEmergencyOverrideForm, setResidentHold }: any = useFormContext();

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleActionClick = (action: Action) => {
        setAnchorEl(null);
        if (action.onClick) action.onClick();
    };

    // let person = {
    //     display_name: `${item.person.first_name} ${item.person.last_name}`,
    //     mit_id: item.person.id,
    //     krb_name: item.person.krb_name
    // };

    const defaultActions = [
        {
            name: 'Emergency Override',
            onClick: () =>
                setEmergencyOverrideForm({
                    show: true,
                    data: { ...item.person }
                }),
            icon: 'first-aid',
            auth_role: 'can_manage_emergency_override'
        },
        {
            name: 'Hold',
            onClick: () =>
                setResidentHold({
                    show: true,
                    data: { ...item.person, manage_holds: item.manage_holds }
                }),
            icon: 'minus-circle',
            auth_role: 'manage_holds',
        }
    ];

    let hasAuth = false;
    let canManageHolds = false;

    defaultActions.map((action: Action) => {
        if (action.auth_role) {

            if (action.auth_role === 'manage_holds') {
                 // eslint-disable-next-line
                for (const [key, value] of Object.entries(item[action.auth_role])) {
                    if (value) {
                        canManageHolds = true;
                    }
                }
            }
            else {
                hasAuth = item[action.auth_role] ? true : hasAuth;
            }
        } else {
            hasAuth = true;
        }
        return action;
    });

    return (
        <>
            {(hasAuth || canManageHolds) && (
                <Tooltip text='More Actions'>
                    <i
                        onClick={handleClick}
                        className={`fas fa-${icon} icon-approve icon ml-2  ${anchorEl ? 'icon-focus' : ''}`}></i>
                </Tooltip>
            )}

            {anchorEl && (
                <Popover anchorEl={anchorEl} setAnchorEl={setAnchorEl} className='icon-actions-popover'>
                    {defaultActions.map((action: Action) => {
                        if (item[action.auth_role + '']) {
                            if (action.auth_role === 'manage_holds' && !canManageHolds)
                                return <></>;

                            return (
                                <div onClick={() => handleActionClick(action)} className='action-icons-list-item'>
                                    <i className={`fas fa-${action.icon}`}></i>
                                    <span>{action.name}</span>
                                </div>
                            );
                        }
                        return '';

                    })}
                </Popover>
            )}
        </>
    );
};

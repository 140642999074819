import React, { useState } from 'react';

import { PeopleSearchController } from '../../api/controller';
import { Lookup } from '../../types/Lookup'

import Select from 'react-select';
import { InputActionMeta } from 'react-select';

let controller = new PeopleSearchController();

interface Props {
    name?: string
    ref?: any
    value?: Lookup | null,
    onChange?: (value: any) => void

}

export const PeopleSearch: React.FC<Props> = ({ name, ref, value, onChange }) => {
    const [searchItems, setSearchItems]: any = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const style = {
    //     control: (base: any) => ({
    //         ...base,
    //         border: "1px solid black",
    //         boxShadow: "none",
    //         "&:focus": {
    //             border: "1px solid red!important"
    //         }
    //     })
    // };

    const handlePersonSearch = (newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            setIsLoading(true);
            setSearchItems([]);

            controller.getPeople(newValue)
                .then(data => {
                    data = data.map((item: any) => ({ id: item.mit_id, name: item.display_name, krb_name: item.krb_name }));
                    setSearchItems(data);
                    setIsLoading(false);
                })
                .catch(err => {
                    setSearchItems([]);
                    // setIsLoading(false);
                })
        }
    }


    return (
        <Select
            name={name}
            ref={ref}
            options={searchItems}
            filterOption={(options: any, input: any) => (true)}
            isLoading={isLoading}
            blurInputOnSelect={false}
            placeholder=""
            onInputChange={(searchTerm: any, options: any) => handlePersonSearch(searchTerm, options)}
            onChange={onChange}
            value={value ? value : null}
            className="people-search"
            getOptionLabel={(option: any) => `${option.name} ${option.krb_name ? `(${option.krb_name})` : ''}`}
            getOptionValue={(option: any) => option.id}
        // styles={style}
        />
    )
}
import React from 'react'

import { ActionType } from '../../../../types/Table';

import { FormRow } from '../../';
import { Dropdown, Input } from '../../../input';

interface Props {
    data: any
    formType: ActionType
    errors: any
    onChangeInput: (value: any, key: string) => void
    lookups: any
}

export const Address: React.FC<Props> = ({ data, errors, formType, onChangeInput, lookups }) => {
    const { states }: any = lookups;

    return (
        <>
            <h6>Address Information</h6>
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.address1}
                        onChange={(value: any) => onChangeInput(value, 'person/address1')}
                        type='string' />
                }
                label='Address Line 1'
                type={formType}
                value={data.person?.address1}
                error={errors.person?.address1}
                required
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.address2}
                        onChange={(value: any) => onChangeInput(value, 'person/address2')}
                        type='string' />
                }
                label='Address Line 2'
                type={formType}
                value={data.person?.address2}
                error={errors.person?.address2}

            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.city}
                        onChange={(value: any) => onChangeInput(value, 'person/city')}
                        type='string' />
                }
                label='City'
                type={formType}
                value={data.person?.city}
                error={errors.person?.city}
                required
            />
            <FormRow
                inputComponent={
                    <Dropdown
                        value={data.person?.state ? states.find((state: any) => state.id === data.person.state) : null}
                        onChange={(value: any) => onChangeInput(value.id, 'person/state')}
                        options={states}
                    />
                }
                label='State'
                type={formType}
                value={data.person?.state}
                error={errors.person?.state}
                required
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.postalCode}
                        onChange={(value: any) => onChangeInput(value, 'person/postalCode')}
                        type='number'
                        maxLength={5} />
                }
                label='Postal Code'
                type={formType}
                value={data.person?.postalCode}
                error={errors.person?.postalCode}
                required
            />

        </>
    );
}
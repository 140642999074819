import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { getFormattedDateString } from '../../../common/Util';

interface Props {
    data: any
    formType: string
}

export const AllocationFormModifyFields: React.FC<Props> = ({ data, formType }) => {
    return (
        <div className='mb-3'>
            {
                data.is_resident &&
                <Row xs='1' xl='2' className='mt-3'>
                    <Col xl='4'>
                        <h5>Resident<span className='required'></span></h5>
                    </Col>
                    <Col xl='8'>
                        {/* <CheckBox disabled={true} checked={state.is_resident} onChange={(e: any) => handleChange(null, null, 'is_campus_access_required', e.target.checked)} /> */}
                        <span className='view-text'> {data.is_resident ? 'True' : 'False'}</span>
                    </Col>
                </Row>
            }
            {
                formType === 'View' &&
                <>
                    <Row xs='1' xl='2' className='mt-3'>
                        <Col xl='4'>
                            <h5 className='mb-0'>Updated By<span className='required'></span></h5>
                        </Col>
                        <Col xl='8'>
                            <span className='view-text'>{data.updated_by}</span>
                        </Col>
                    </Row>
                    <Row xs='1' xl='2' className='mt-3'>
                        <Col xl='4'>
                            <h5 className='mb-0'>Updated On<span className='required'></span></h5>
                        </Col>
                        <Col xl='8'>
                            <span className='view-text'>{getFormattedDateString(data.updated_on)}</span>
                        </Col>
                    </Row>
                </>
            }
        </div>
    );
}
import React, { useEffect, useState } from 'react'
import { EmergencyOverrideController } from '../../api/controller/EmergencyOverride'
import Loading from '../Loading'
import { getDateTimeString, convertToEst } from '../../common/Util'
import { useFormContext, useAllocationContext, useSearchContext, useAppContext } from '../../context'
import { Banner } from '.'

import { Drawer, Button, DrawerType, DrawerSize, ButtonState, ButtonType } from '@mit/hui'
import { Row, Col } from 'react-bootstrap'
import { FormRow } from './FormRow'
import { DatePicker } from '../input'

interface Props {}

const controller = new EmergencyOverrideController()

export const EmergencyOverrideForm: React.FC<Props> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [banners, setBanners]: any = useState({ id: null, messages: [] })
    const [state, setState]: any = useState({})
    const [errors, setErrors]: any = useState({})
    const { emergencyOverrideForm, setEmergencyOverrideForm }: any = useFormContext()
    const { auth }: any = useAppContext()
    const { setRefreshAllocation }: any = useAllocationContext()
    const { setRefreshAllocation: setSearchRefreshAllocation }: any = useSearchContext()
    const [data, setData] = useState<any>([])

    const validateForm = () => {
        let errors: any = {}

        if (!state.start_date) errors.start_date = 'Start date is required'
        if (!state.end_date) errors.end_date = 'End date is required'

        let start_date = new Date(state.start_date),
            end_date = new Date(state.end_date)

        if (start_date > end_date) errors.end_date = 'End date must be greater than start date'

        setErrors(errors)
        return Object.keys(errors).length === 0
    }

    const createOverride = () => {
        let valid = validateForm()
        if (valid) {
            setIsLoading(true)
            setBanners({ id: null, messages: [] })
            const body = {
                ...state,
                mit_id: data.id,
            }
            controller
                .createOverride(body)
                .then((data) => {
                    setBanners({
                        id: 1,
                        header: 'Success',
                        text: data.has_override ? 'Emergency Override Extended' : 'Emergency Override Created',
                        type: 'success',
                        messages: [],
                    })
                    refreshOverride()
                    refreshAllocations()
                })
                .catch((error) => {
                    setBanners({
                        id: 1,
                        header: 'Error',
                        text: error,
                        type: 'danger',
                        messages: [],
                    })
                    setIsLoading(false)
                })
        }
    }

    const deleteOverride = () => {
        setIsLoading(true)
        setBanners({ id: null, messages: [] })
        controller
            .deleteOverride(data?.id)
            .then((data) => {
                setBanners({
                    id: 1,
                    header: 'Success',
                    text: 'Emergency Override Deleted',
                    type: 'success',
                    messages: [],
                })
                refreshOverride()
                refreshAllocations()
            })
            .catch((error) => {
                setBanners({
                    id: 1,
                    header: 'Error',
                    text: error,
                    type: 'danger',
                    messages: [],
                })
                setIsLoading(false)
            })
    }

    const refreshAllocations = () => {
        setRefreshAllocation((prev: any) => !prev)
        setSearchRefreshAllocation((prev: any) => !prev)
    }

    const refreshOverride = () => {
        if (data.id) {
            setIsLoading(true)
            controller.getOverride(data?.id).then((data) => {
                setState((prev: any) => ({
                    ...prev,
                    ...data,
                }))
                setIsLoading(false)
            })
        }
    }

    const handleClose = () => {
        setBanners({ id: null, messages: [] })
        setEmergencyOverrideForm({
            show: false,
            data: {},
        })
    }

    const handleChange = (value: string, key: string) => {
        setState((prev: any) => {
            return {
                ...prev,
                [key]: value,
            }
        })
        setErrors((prev: any) => ({ ...prev, [key]: '' }))
    }

    useEffect(() => {
        setData(emergencyOverrideForm.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emergencyOverrideForm])

    useEffect(() => {
        refreshOverride()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id])

    return (
        <Drawer
            show={emergencyOverrideForm.show}
            onClose={handleClose}
            type={DrawerType.Right}
            size={DrawerSize.Small}
            themedColor='medical'
            header={
                <div className='d-flex flex-column'>
                    <h3>Emergency Override</h3>
                    <h5 className='mt-2'>
                        {data.name} ({data.krb_name}){' '}
                    </h5>
                </div>
            }
            footer={[]}
            contents={
                <>
                    <Loading loaded={isLoading} />
                    <Banner banners={banners} setBanners={setBanners} />

                    <Row className='mb-3'>
                        <Col>
                            <h6>An emergency override will temporarily lift a lockout for up to {auth.max_days_emergency_override} days.</h6>
                        </Col>
                    </Row>
                    <FormRow
                        inputComponent={
                            <DatePicker
                                placeholder='MM/DD/YYYY'
                                value={state.start_date ? state.start_date : null}
                                onChange={(value: string) => handleChange(value, 'start_date')}
                            />
                        }
                        label='Start Date'
                        type='EDIT'
                        value={state.start_date}
                        error={errors.start_date}
                        required
                    />
                    <FormRow
                        inputComponent={
                            <DatePicker
                                placeholder='MM/DD/YYYY'
                                value={state.end_date ? state.end_date : null}
                                onChange={(value: string) => handleChange(value, 'end_date')}
                            />
                        }
                        label='End Date'
                        type='EDIT'
                        value={state.end_date}
                        error={errors.end_date}
                        required
                    />

                    {state.has_override && (
                        <>
                            <Row className='mt-3'>
                                <Col>
                                    <h6>
                                        Override Created On: <b>{getDateTimeString(convertToEst(state.updated_on))}</b>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <h6>
                                        Override Created By: <b>{state.updated_by}</b>
                                    </h6>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row className=''>
                        <Col>
                            <h6 className='mt-3 alert-text'>Allow up to 30 minutes for building access to take affect.</h6>
                        </Col>
                    </Row>

                    <div className='mt-5'>
                        {!state.has_override && (
                            <Button onClick={createOverride} text='Create Override' state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} />
                        )}

                        {state.has_override && (
                            <>
                                <Button
                                    onClick={createOverride}
                                    type={ButtonType.Primary}
                                    text='Update Override'
                                    state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                />
                                &nbsp;&nbsp;
                                <Button
                                    onClick={deleteOverride}
                                    type={ButtonType.TextNaked}
                                    text=' Remove Override'
                                    state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                />
                            </>
                        )}
                    </div>
                </>
            }
        />
    )
}

import React, { useEffect, useState } from 'react'
import { Drawer, DrawerSize, DrawerType, Button, ButtonType } from '@mit/hui'
import { FormRow } from '../../forms'
import { Checkbox, Dropdown, Input } from '../../input'
import { getFilterItems } from '../../../common'
import cloneDeep from 'clone-deep'

const initialFilters = {
    person: [],
    title: [],
    department: [],
    buildings: '',
    cores: '',
    essential: null,
}

interface Props {
    items: any
    show: boolean
    onClose: () => void
    onFilter: (items: any) => void
    selectedItem: any
    canViewEssential: boolean
}

export const AllocationFilter: React.FC<Props> = ({ items, show, onClose, onFilter, selectedItem, canViewEssential }) => {
    const [filters, setFilters] = useState<any>(cloneDeep(initialFilters))
    const [options, setOptions] = useState<any>({})

    const handleChange = (value: any, key: string) => {
        setFilters((prev: any) => ({ ...prev, [key]: value }))
        // console.log(options);
    }

    const handleFilter = (filterObj: any, bClose: boolean = true) => {
        let data = []
        if (!filterObj) filterObj = filters
        filterObj = { ...filterObj, buildings: filterObj.buildings.trim(), cores: filterObj.cores.trim() }
        for (let item of items) {
            let bfilterObj = {
                person:
                    filterObj.person && filterObj.person.length
                        ? filterObj.person.filter((person) => person.name === item.person.name).length > 0
                        : true,
                title:
                    filterObj.title && filterObj.title.length ? filterObj.title.filter((title) => title.name === item.person.title).length > 0 : true,
                department:
                    filterObj.department && filterObj.department.length
                        ? filterObj.department.filter((department) => department.name === item.department.name).length > 0
                        : true,
                buildings: filterObj.buildings
                    ? item.buildings.filter(
                          (building: any) =>
                              building.name.toLowerCase().indexOf(filterObj.buildings.toLowerCase()) !== -1 ||
                              `${building.id}`.indexOf(filterObj.buildings) !== -1
                      ).length > 0
                    : true,
                cores: filterObj.cores
                    ? item.cores.filter(
                          (core: any) =>
                              core.name.toLowerCase().indexOf(filterObj.cores.toLowerCase()) !== -1 || `${core.id}`.indexOf(filterObj.cores) !== -1
                      ).length > 0
                    : true,
                essential: filterObj.essential !== null && canViewEssential ? item.is_essential === filterObj.essential : true,
            }

            if (Object.keys(bfilterObj).filter((filter: string) => bfilterObj[filter]).length === Object.keys(bfilterObj).length) {
                data.push(item)
            }
        }
        // console.log(data);
        onFilter(data)
        if (bClose) onClose()
    }

    const clearFilter = () => {
        let filterObj = cloneDeep(initialFilters)
        setFilters(filterObj)
        handleFilter(filterObj, false)
    }

    useEffect(() => {
        if (show) {
            let options = {
                person: getFilterItems(items, 'person/name'),
                title: getFilterItems(items, 'person/title'),
                department: getFilterItems(items, 'department/name'),
            }
            setOptions(options)
        }
        //eslint-disable-next-line
    }, [show])

    useEffect(() => {
        clearFilter()
        //eslint-disable-next-line
    }, [selectedItem])

    return (
        <Drawer
            show={show}
            type={DrawerType.Left}
            size={DrawerSize.Small}
            themedColor='medical'
            onClose={onClose}
            header={<h4>Filter Allocations</h4>}
            footer={[]}
            contents={
                <>
                    <FormRow
                        inputComponent={
                            <Dropdown
                                options={options.person}
                                onChange={(value: any) => handleChange(value, 'person')}
                                value={filters.person}
                                isMulti
                            />
                        }
                        label='Person'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={
                            <Dropdown options={options.title} onChange={(value: any) => handleChange(value, 'title')} value={filters.title} isMulti />
                        }
                        label='Title'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={
                            <Dropdown
                                options={options.department}
                                onChange={(value: any) => handleChange(value, 'department')}
                                value={filters.department}
                                isMulti
                            />
                        }
                        label='Department'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={<Input onChange={(value: any) => handleChange(value, 'buildings')} value={filters.buildings} />}
                        label='Buildings'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={<Input onChange={(value: any) => handleChange(value, 'cores')} value={filters.cores} />}
                        label='Core Facilities'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    {canViewEssential && (
                        <FormRow
                            inputComponent={
                                <Checkbox checked={filters.essential ? true : false} onChange={(value: any) => handleChange(value, 'essential')} />
                            }
                            label='Essential'
                            labelPlacement='top'
                            type='EDIT'
                            fullWidth
                        />
                    )}
                    <div className='mt-3 ml-3'>
                        <Button type={ButtonType.Primary} icon='filter' text='Filter' onClick={handleFilter} />
                        &nbsp;&nbsp;
                        <Button type={ButtonType.Ghost | ButtonType.Primary} text='Clear Filters' onClick={clearFilter} />
                    </div>
                </>
            }
        />
    )
}

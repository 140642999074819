import moment from 'moment-timezone';
export const newSponsoredAccountData = {
    startDate: moment().tz('America/New_York').format('YYYY-MM-DD'),
    endDate: null,
    sponsor: null,
    department: null,
    officeAddress: null,
    costObject: null,
    all_buildings: false,
    buildings: [],
    cores: [],
    reasonForAccess: null,
    person: {
        id: null,
        firstName: null,
        lastName: null,
        dob: null,
        organization: null,
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null
    },
    paidByMit: false,
    dailyHealthCheck: false,
    existingMitid: false,
    is_essential: false,
    mitidCardNeeded: true,
    mitidDeliveryMethod: 'H',
    enforce_attestation: true,
    enforce_medical: false,
    enforce_training: true,
    enforce_waiver: true,
    request_mit_medical: false
}

export const sponsoredAccountStatus = [
    {
        id: 'P',
        name: 'Pending'
    },
    {
        id: 'R',
        name: 'Requested'
    }, {
        id: 'I',
        name: 'Issued'
    },
    {
        id: 'D',
        name: 'Deleted/Suspended'
    }
]

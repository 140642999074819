import React, { useEffect } from 'react'

import { FilteredList, ListItemProps, ListItemState, TextboxProps, TextboxType } from '@mit/hui'

import { SponsoredAccountController } from '../../api/controller'
import { ListItem } from '../../types/Lookup'

let sponsoredAccountController = new SponsoredAccountController()

interface Props {
    context: any
}

export const SponsoredAccountMaster: React.FC<Props> = ({ context }) => {
    const { setFirstLoad, listItems, isLoading, selectedItem, setSelectedItem, setListItems, setIsLoading }: any = context

    const searchOptions: TextboxProps = {
        name: 'Name',
        placeholderText: 'Filter List',
        startIcon: 'search',
        type: TextboxType.IconStart,
    }

    const filterItems: ListItemProps[] = listItems.map((item: any, index: number) => ({
        id: item.id,
        text: item.name,
        // icon: 'building',
        itemIndex: index,
        state: selectedItem.id === item.id ? ListItemState.Active : ListItemState.None,
        onClick: () => setSelectedItem(item),
    }))

    useEffect(() => {
      getData()
        //eslint-disable-next-line
    }, [])

    const getData = async () => {
      setIsLoading((state: any) => ({ ...state, list: true, items: true }))

      let done = false
      let size = 4000
      let from = 1
      let to = size
      let finalData: ListItem[] = []
      let safety = 5 // Stop after 5 requests

      while(!done && safety-- > 0){
        const data = await sponsoredAccountController.getAccountsPaginated(from, to) as ListItem[]
        from += size
        to += size

        if (data.length > 0){
          for(let index = 0; index < data.length; index++){
            const existingIndex = finalData.findIndex(f => f.id === data[index].id)
            if(existingIndex > -1)
              finalData[existingIndex].items = [...finalData[existingIndex].items, ...data[index].items]
            else
              finalData.push(data[index])
          }
        }
        else{
          done = true
        }
      }

      finalData = finalData.sort((a,b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }).map(data => {
        return {
          ...data,
          items: data.items.sort((a,b) => {
            if (a.person.last_name < b.person.last_name) return -1;
            if (a.person.last_name > b.person.last_name) return 1;
            return 0;
          })
        }
      })

      setListItems(finalData)
      if (finalData.length) {
        let firstItem = finalData[0]
        setSelectedItem(firstItem)
      }

      setIsLoading((state: any) => ({ ...state, list: false, items: false }))
      setFirstLoad(false)
    };

    return (
        <FilteredList
            name=''
            isLoading={isLoading.list}
            selectable={true}
            compact={true}
            searchOptions={searchOptions}
            items={filterItems}
            height={60}
        />
    )
}

import React from 'react'
import { ReleaseNotes, ReleaseNoteItemProps, NavBar, NavBarTypes, AtlasPage, BasicLayout, ResponsiveTypes } from '@mit/hui'
import { getEnv } from '../../common'

interface Props {
    placeholder?: any
}

export const ReleaseNote: React.FC<Props> = () => {
    const releaseNotes: ReleaseNoteItemProps[] = [
        {
            date: '10/10/2020',
            items: [{ heading: 'New Design', description: 'Updated look and feel for Covid Access and Covid Pass.' }],
        },
        {
            date: '10/17/2020',
            items: [{ heading: 'Additional Filters', description: 'Authorized users can filter on residential holds or emergency override.' }],
        },
    ]

    return (
        <>
            <AtlasPage
                navigation={
                    <NavBar
                        responsiveType={ResponsiveTypes.CollapseSome}
                        type={NavBarTypes.IconText}
                        tabs={[{ icon: 'chevron-left', text: 'Back', iconOnly: false, to: '/' }]}
                    />
                }
                gaId={''}
                theme={'medical'}
                environment={getEnv()}
                name={'Covid Access'}
                content={
                    <BasicLayout
                        startState={true}
                        content={
                            <div>
                                <ReleaseNotes items={releaseNotes} />
                            </div>
                        }
                        contentTitle="What's new"
                    />
                }
            />
        </>
    )
}

import { BaseApiController, Region, RegionalConfig, AuthProvider, AppConfig } from '@mit/aws-react'
import appConfig from '../../app-config'

export class BaseController extends BaseApiController {
    readonly apiHost: string
    readonly apiPath: string
    readonly apiPathDigitalId: string
    readonly apiPathVaccine: string
    readonly apiPathPass: string
    readonly apiPathMedical: string

    public readonly appConfig: AppConfig
    private readonly activeRegion: RegionalConfig

    constructor() {
        super()

        this.appConfig = (appConfig as unknown) as AppConfig
        const region = new Region(this.appConfig)

        this.activeRegion = region.getActiveRegionConfig()
        this.apiHost = `https://${this.activeRegion.api.domain}`
        this.apiPath = this.activeRegion.api.paths.covid
        this.apiPathDigitalId = this.activeRegion.api.paths['digital-id']
        this.apiPathVaccine = this.activeRegion.api.paths.vaccine
        this.apiPathPass = this.activeRegion.api.paths.pass
        this.apiPathMedical = this.activeRegion.api.paths.medical
    }

    /*
    If you do not use cognito, implement your authorization header logic here
  */
    public async getAuthorizationHeaders(): Promise<Record<string, string>> {
        const token = await new AuthProvider(this.activeRegion).getToken()

        return {
            Authorization: `Bearer ${token}`,
        }
    }

    // async getHeaders(method: string, file?: boolean, fileName?: any, contentType?: string): Promise<Headers> {
    //     let token = await new AuthProvider().getToken()
    //     token = token.token

    //     let headers: Headers = new Headers({
    //         // 'Method': method
    //     })

    //     if (file) {
    //         headers.append('Content-Type', contentType ? contentType : 'binary/octet-stream')
    //         if (!contentType) {
    //             headers.append('Content-Filename', fileName)
    //             headers.append('Authorization', 'Bearer ' + token)
    //         }
    //     } else {
    //         headers.append('Content-Type', 'application/json')
    //         headers.append('Authorization', 'Bearer ' + token)
    //     }

    //     return headers
    // }

    // async useFetch(method: string, fetchKey: string, url: string, body?: any, file?: any, fileName?: string, contentType?: string): Promise<any> {
    //     if (fetchKey && this.fetchController[fetchKey]) {
    //         this.fetchController[fetchKey].abort()
    //     }

    //     this.fetchController[fetchKey] = new AbortController()
    //     let { signal } = this.fetchController[fetchKey]

    //     let options: any = {
    //         method: method,
    //         headers: await this.getHeaders(method, file, fileName, contentType),
    //         signal,
    //     }
    //     if (body) {
    //         options['body'] = file ? body : JSON.stringify(body)
    //     }

    //     let response = await fetch(url, options)

    //     //clear out controller
    //     this.fetchController[fetchKey] = null

    //     return response
    // }

    async getProfile(): Promise<any> {
        let response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getProfilePicture(): Promise<any> {
        let response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

        if (response.status === 200) {
            return response.blob()
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getUserAuth(): Promise<any> {
        let response = await this.useFetch('GET', 'initialize', `${this.apiHost}/${this.apiPath}/allocations/authorization`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getBuildingsLookup(): Promise<any> {
        let response = await this.useFetch('GET', 'buildingsLookup', `${this.apiHost}/${this.apiPath}/buildings`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getDepartmentsLookup(): Promise<any> {
        let response = await this.useFetch('GET', 'departmentsLookup', `${this.apiHost}/${this.apiPath}/departments`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getAllocationTypesLookup(): Promise<any> {
        let response = await this.useFetch('GET', 'allocationTypesLookup', `${this.apiHost}/${this.apiPath}/allocation_types`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getCoreFacilitiesLookup(): Promise<any> {
        let response = await this.useFetch('GET', 'coreFacilitiesLookup', `${this.apiHost}/${this.apiPath}/core_facilities`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getTransportMethodsLookup(): Promise<any> {
        let response = await this.useFetch('GET', 'transport_methods', `${this.apiHost}/${this.apiPath}/transport_methods`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    //api calls that are shared between multiple components

    async getPeople(searchTerm: string): Promise<any> {
        let response = await this.useFetch('GET', 'lookups/mit-person', `${this.apiHost}/${this.apiPathDigitalId}/search?q=${searchTerm}`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async createAllocation(body: any): Promise<any> {
        let response = await this.useFetch('POST', 'allocations', `${this.apiHost}/${this.apiPath}/allocations`, body)

        if (response.status === 201) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    // async uploadAllocation(file: any, fileName: any, userType: string, uploadType: string): Promise<any> {

    //     let response = await this.useFetch('POST', 'allocations/upload', `${this.apiHost}/${this.apiPath}/allocations/upload?filename=${fileName}${userType ? `&allocation_type=${userType}` : ''}&action=${uploadType}`, file, true, fileName);

    //     if (response.status === 201) {
    //         let data = response.json();
    //         return data;
    //     } else {
    //         let error = await response.json();
    //         return Promise.reject(error.message);
    //     }

    // }

    async getAllocationS3Url(file_name: any, allocation_type: any, action: any): Promise<any> {
        let response = await this.useFetch(
            'GET',
            'get/vcs_upload_url',
            `${this.apiHost}/${this.apiPath}/allocations/upload?file_name=${file_name}&allocation_type=${allocation_type}&action=${action}`
        )

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async uploadAllocation(url: string, file: any, fileName: any): Promise<any> {
        let response = await this.useFetch(
            'PUT',
            'vcs/upload',
            url,
            file,
            { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'Content-Filename': fileName },
            true
        )

        if (response.status === 200) {
            return true
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async updateAllocation(id: string, body: any): Promise<any> {
        let response = await this.useFetch('PUT', 'allocations', `${this.apiHost}/${this.apiPath}/allocations/${id}`, body)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async deleteAllocation(id: string): Promise<any> {
        let response = await this.useFetch('DELETE', 'allocations', `${this.apiHost}/${this.apiPath}/allocations/${id}`)

        if (response.status === 204) {
            // let data = response.json();
            return 'DELETED'
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getLabels(): Promise<any> {
        let response = await this.useFetch('GET', 'labels', `${this.apiHost}/${this.apiPath}/labels`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getPicture(id: string): Promise<any> {
        let response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPath}/picture/${id}`)

        if (response.status === 200) {
            // let data = response.json();
            // let outside = URL.createObjectURL(response)
            // console.log(outside)
            return response.blob()
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getHealthCheck(id: string): Promise<any> {
        let response = await this.useFetch('GET', 'health-check', `${this.apiHost}/${this.apiPath}/health-check/${id}`)

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getDepartments(assignment_type_id: string, sKey: string): Promise<any> {
        let response = await this.useFetch(
            'GET',
            `${sKey}/departments`,
            `${this.apiHost}/${this.apiPath}/departments?assignment_type_id=${assignment_type_id}`
        )

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async getAllocations(assignment_type_id: string, sKey: string, dlc_key?: string): Promise<any> {
        let response = await this.useFetch(
            'GET',
            `${sKey}/allocations`,
            `${this.apiHost}/${this.apiPath}/allocations?assignment_type_id=${assignment_type_id}${
                dlc_key ? `&dlc_key=${encodeURIComponent(dlc_key)}` : ''
            }`
        )

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }
}

import React from 'react'

import { useAppContext } from '../../context/AppContext'

import { NavBar, NavBarTypes, NavItemState, ResponsiveTypes } from '@mit/hui'

interface Props {
    placeholder?: any
}

export const MainNavBar: React.FC<Props> = () => {
    const { auth }: any = useAppContext()

    const tabs = [
        {
            icon: 'search',
            iconOnly: false,
            text: 'Search',
            to: '/search',
            state: window.location.pathname === '/search' ? NavItemState.Active : NavItemState.None,
        },
    ]

    if (auth.roles) {
        let roles = auth.roles

        for (let role in roles) {
            if (parseInt(role) && (roles[role].view || roles[role].edit)) {
                let obj = roles[role]
                tabs.push({
                    icon: 'users',
                    iconOnly: false,
                    text: obj.name,
                    to: '/allocation/' + role,
                    state: window.location.pathname === '/allocation/' + role ? NavItemState.Active : NavItemState.None,
                })
            }
        }

        // if (roles[1].view || roles[1].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: 'PI',
        //         to: '/allocation/1',
        //         state: window.location.pathname === '/allocation/1' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        // if (roles[2].view || roles[2].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: 'Core Facilities',
        //         to: '/allocation/2',
        //         state: window.location.pathname === '/allocation/2' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        // if (roles[3].view || roles[3].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: "Instruction and Administration",
        //         to: '/allocation/3',
        //         state: window.location.pathname === '/allocation/3' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        // if (roles[6].view || roles[6].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: "Vendor / Suppliers",
        //         to: '/allocation/6',
        //         state: window.location.pathname === '/allocation/6' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        // if (roles[8].view || roles[8].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: "One Time",
        //         to: '/allocation/8',
        //         state: window.location.pathname === '/allocation/8' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        // if (roles[9].view || roles[9].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: "Lincoln Lab",
        //         to: '/allocation/9',
        //         state: window.location.pathname === '/allocation/9' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        // if (roles[10].view || roles[10].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: "Residents",
        //         to: '/allocation/10',
        //         state: window.location.pathname === '/allocation/10' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        // if (roles[12].view || roles[12].edit) {
        //     tabs.push({
        //         icon: 'users',
        //         iconOnly: false,
        //         text: "None - Laboratory Research",
        //         to: '/allocation/12',
        //         state: window.location.pathname === '/allocation/12' ? NavItemState.Active : NavItemState.None
        //     });
        // }

        if (auth.can_view || auth.can_edit) {
            tabs.push({
                icon: 'users',
                iconOnly: false,
                text: 'DLC Sponsored Accounts',
                to: '/sponsored-accounts',
                state: window.location.pathname === '/sponsored-accounts' ? NavItemState.Active : NavItemState.None,
            })
        }
    }

    return <NavBar type={NavBarTypes.IconText} tabs={tabs} responsiveType={ResponsiveTypes.CollapseSome} />
}

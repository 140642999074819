import React from 'react'

import Select from 'react-select';

interface Props {
    name?: string
    ref?: any
    value?: any
    options?: any
    placeholder?: string
    onChange?: (item: any) => void
    isMulti?: boolean
    disabled?: boolean
}

export const Dropdown: React.FC<Props> = ({ name, ref, value, options, placeholder, onChange, isMulti, disabled }) => {
    return (
        <Select
            name={name}
            ref={ref}
            isMulti={isMulti}
            options={options}
            isLoading={options ? options.length === 0 : true}
            blurInputOnSelect={false}
            placeholder={placeholder ? placeholder : ''}
            onChange={onChange}
            value={value ? value : null}
            className="people-search"
            isDisabled={disabled ? true : false}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id} />
    )
}
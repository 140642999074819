import React, { useState, createContext } from 'react';

export const FormContext = createContext({});

interface Props {
    children: React.ReactNode
}

export const initialForm = {
    show: false,
    data: {}
}


export const FormProvider: React.FC<Props> = ({ children }) => {
    const [allocationForm, setAllocationForm] = useState<object>({ ...initialForm });
    const [allocationUploadForm, setAllocationUploadForm] = useState<object>({ ...initialForm });
    const [sponsoredAccountForm, setSponsoredAccountForm] = useState<object>({ ...initialForm });
    const [sponsoredAccountUploadForm, setSponsoredAccountUploadForm] = useState<object>({ ...initialForm });
    const [sponsoredAccountEmail, setSponsoredAccountEmail] = useState<object>({ ...initialForm });
    const [emergencyOverrideForm, setEmergencyOverrideForm] = useState<object>({ ...initialForm });
    const [residentHold, setResidentHold] = useState<object>({ ...initialForm });

    const exportValue = {
        allocationForm,
        setAllocationForm,
        allocationUploadForm,
        setAllocationUploadForm,
        sponsoredAccountForm,
        setSponsoredAccountForm,
        sponsoredAccountUploadForm,
        setSponsoredAccountUploadForm,
        emergencyOverrideForm,
        setEmergencyOverrideForm,
        residentHold,
        setResidentHold,
        sponsoredAccountEmail,
        setSponsoredAccountEmail
    }

    return (
        <FormContext.Provider value={exportValue}>
            {children}
        </FormContext.Provider>
    )

}

export const useFormContext = () => {
    const context = React.useContext(FormContext)
    if (context === undefined) {
        throw new Error('useFormContext must be used within a LookupsProvider')
    }
    return context
}


import { getStage } from '@mit/aws-react'
import moment from 'moment'
import moment_timezone from 'moment-timezone'
import cloneDeep from 'clone-deep'
import appConfig from '../app-config'

type EnvReturn = 'dev' | 'staging' | 'production'

export const getEnv = (): EnvReturn => {
  switch (getStage(appConfig)) {
    case 'develop':
      return 'dev'
    case 'release':
      return 'staging'
    case 'master':
      return 'production'
  }
  return 'production'
}

export const getEnvPath = (): string => {
  switch (getStage(appConfig)) {
    case 'develop':
      return '-dev'
    case 'release':
      return '-release'
  }
  return ''
}

export const getDateString = (date: Date) => {
  return date.toISOString().split('T')[0]
}

export const getDefaultEndDateString = () => {
  let date = new Date()
  let month = date.getUTCMonth()
  let year = date.getUTCFullYear()

  //if decemeber return end of next year
  return `${month === 11 ? year + 1 : year}-12-31`
}

export const getFormattedDateString = (date: string) => {
  if (date) {
    let aDate
    date = date.indexOf('T') >= 0 ? date.split('T')[0] : date.split(' ')[0]
    aDate = date.split('-')
    return `${aDate[1]}/${aDate[2]}/${aDate[0]}`
  }
  return ''
}

export const get12HourTimeString = (time: string) => {
  let date = new Date()
  // let options = {
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: true,
  // }
  if (time) {
    date.setHours(parseInt(time.split(':')[0]))
    date.setMinutes(parseInt(time.split(':')[1]))

    let timeString = date.toLocaleString().split(', ')?.[1]

    return timeString
  }
  return time
}

export const toUtc = (localTime: string): string => {
  return moment.utc(moment(localTime)).format()
}

export const getDayOfWeekName = (day_of_week: number) => {
  switch (day_of_week) {
    case 7:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
  }
}

export const getDateTimeString = (dateTime: string, convertDateToEst?: boolean) => {
  if (dateTime) {
    if (convertDateToEst) dateTime = convertToEst(dateTime)

    dateTime = dateTime.replace('T', ' ')
    let [date, time] = dateTime.split(' ')

    return `${getFormattedDateString(date)} ${get12HourTimeString(time)}`
  }

  return dateTime
}

export const getTrimmedPhone = (phone: string) => {
  phone = cloneDeep(phone)
  if (phone && phone.indexOf('+') >= 0) {
    phone = phone.slice(2, phone.length)
  }
  return phone
}

export const getFormattedPhoneNumber = (phone: string) => {
  if (!phone) return ''

  if (phone.length > 9) {
    phone = phone.substr(phone.length - 10, phone.length)
  }
  let areaCode = phone.substr(0, 3),
    firstDigits = phone.substr(3, 3),
    lastDigits = phone.substr(6, phone.length)

  console.log(areaCode, firstDigits, lastDigits)

  return `(${areaCode})${firstDigits}-${lastDigits}`
}

export const checkHandleError = (error: string) => {
  error = error.toString()
  if (error.indexOf('The user aborted a request') >= 0) return false

  return true
}

export const convertToEst = (date: string) => {
  let newDate = moment_timezone.utc(date).tz('America/New_York')
  return newDate.format()
}

// export default {
//     getDateString,
//     getDefaultEndDateString,
//     get12HourTimeString,
//     getFormattedDateString,
//     toUtc,
//     getDayOfWeekName,
//     getTrimmedPhone,
//     getFormattedPhoneNumber
// };

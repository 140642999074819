import React from 'react'
import Tooltip from '../../Tooltip'
import { useFormContext } from '../../../context'

interface Props {
    item: any
}

export const SponsoredAccountActions: React.FC<Props> = ({ item }) => {
    const { setSponsoredAccountForm, setSponsoredAccountEmail }: any = useFormContext()

    const handleClick = (type: string) => {
        setSponsoredAccountForm({
            show: true,
            type: type,
            data: item
        })
    }

    const resendEmail = () => {
        setSponsoredAccountEmail({
            show: true,
            data: item
        })
    }


    const actions: any[] = [
        {
            name: 'View',
            onClick: () => handleClick('VIEW'),
            icon: 'info-circle',
            auth_role: null,
            class: 'icon-info'
        },
        {
            name: 'Edit',
            onClick: () => handleClick('EDIT'),
            icon: 'edit',
            auth_role: 'can_edit',
            class: 'icon-edit ml-2'
        },
        {
            name: 'Delete',
            onClick: () => handleClick('DELETE'),
            icon: 'times-circle',
            auth_role: 'can_edit',
            class: 'icon-clear ml-2'
        }
    ]

    if (item.status?.kerberos_id !== 'I') {
        actions.push({
            name: 'Resend Registration Email',
            onClick: resendEmail,
            icon: 'envelope',
            auth_role: 'can_edit',
            class: 'icon-info ml-2'
        })
    }



    return (
        <div>
            {actions.map((action: any) => {
                if (item[action.auth_role] || !action.auth_role)
                    return <Tooltip text={action.name}>
                        <i
                            onClick={action.onClick}
                            className={`fas fa-${action.icon} ${action.class} icon`}></i>
                    </Tooltip>
                else return ''
            })}
        </div>
    );
}
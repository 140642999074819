import React from 'react'


interface Props {
    name?: string
    ref?: any
    type?: string
    value?: string
    onChange?: (e: any) => void
    placeholder?: string
    required?: boolean
    maxLength?: number
}

export const Input: React.FC<Props> = ({ name, ref, type, value, onChange, placeholder, required, maxLength }) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        let value = event.target.value;

        if (maxLength) {
            value = value.substring(0, maxLength);
        }
        if (onChange)
            onChange(value);
    }

    return (
        // <Textbox
        //     name={name || 'Input'}
        //     // ref={ref}
        //     placeholderText={placeholder}
        //     type={TextboxType.Normal}
        //     // properties={{ onChange: ()=>  }}
        //     value={value}
        //     required={required ? true : false}
        // />
        <input
            value={value}
            type={type ? type : 'string'}
            onChange={handleChange}
            className='input'
            placeholder={placeholder}
        />
    );
}
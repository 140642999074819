import React, { useState } from 'react'
import { AdvancedTable, Text } from '@mit/hui'
import { useEffect } from 'react'
import { VaccineController, VaccineStatusResp } from '../api/controller'
import { Checkbox } from './input'
import { Button } from 'react-bootstrap'

interface VaccineStatusProps {
  id: string
}
// interface ExemptionHolds {
//   id: string
//   text: string
//   value: boolean
//   medicalOnly: boolean
// }

interface HoldExemption {
  hr: boolean
  temp: boolean
}

const getVaccineStatusText = (status: string) => {
  switch (status) {
    case 'full':
      return 'Fully Vaccinated'
    case 'partial':
      return 'Partially Vaccinated'
    case 'exempt':
      return 'Exempt'
    case 'none':
      return 'Not Vaccinated'
    case 'No Status':
      return 'Unknown Vaccination Status'
  }
}

export const VaccineStatus: React.FC<VaccineStatusProps> = ({ id }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<VaccineStatusResp>(null)
  // const [exemptions, setExemptions] = useState<ExemptionHolds[]>()
  const [holdExemption, setHoldExemption] = useState<HoldExemption>({ hr: false, temp: false })

  useEffect(() => {
    setIsLoading(true)

    getData()

    //eslint-disable-next-line
  }, [id])

  const getData = async () => {
    const controller = new VaccineController()
    let resp: any = await Promise.allSettled([controller.getVaccineStatus(id), controller.fetchPersonVaccineExemption(id)])

    if (resp[0].status === 'fulfilled') {
      setData(resp[0].value)
    }
    if (resp[1].status === 'fulfilled') {
      setHoldExemption({ hr: resp[1].value.hr, temp: resp[1].value.temp })
    }

    setIsLoading(false)
  }

  // const submit = (data: any) => {
  //   setIsLoading(true)
  //   const body = holdExemption
  //   const controller = new VaccineController()
  //   controller
  //     .putPersonVaccineExemption(id, body)
  //     .then((response) => {
  //       if (response.success) {
  //         controller.fetchPersonVaccineExemption(id).then((response) => {
  //           getData()
  //         })
  //       } else {
  //         setIsLoading(false)
  //       }
  //     })
  //     .catch(() => {
  //       setIsLoading(false)
  //     })
  // }

  const onChangeExemption = (id: string, checked: boolean) => {
    setHoldExemption((prev) => ({ ...prev, [id]: checked }))
  }

  if (isLoading)
    return (
      <AdvancedTable
        columns={[]}
        items={[]}
        isLoading={isLoading}
        noDataText=''
        showNoData={true}
        showFilterButton={false}
        showExportButton={false}
      />
    )

  return (
    <>
      <h5>Vaccine Status</h5>
      <Text content={getVaccineStatusText(data?.vaccination_status)} />

      <h5>Vaccine Hold Status</h5>
      <Text content={data?.hold_status} />

      <div>
        <h5>Vaccine Exemptions</h5>
        <h6 style={{ paddingRight: '20px' }}>HR Exemption</h6>
        <Checkbox checked={holdExemption.hr} disabled onChange={(checked: boolean) => onChangeExemption('hr', checked)} />

        <h6 style={{ paddingRight: '20px' }}>Temp Exemption</h6>
        <Checkbox checked={holdExemption.temp} disabled onChange={(checked: boolean) => onChangeExemption('temp', checked)} />

        <br></br>
{/* 
        <Button disabled={isLoading} onClick={submit} className=''>
          Submit
        </Button> */}
      </div>
    </>
  )
}

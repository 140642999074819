import React from 'react'

import { ActionType } from '../../../../types/Table';

import { FormRow } from '../../';
import { Input, DatePicker, MaskedInput } from '../../../input';
import { getFormattedPhoneNumber, getFormattedDateString } from '../../../../common/Util';

interface Props {
    data: any
    formType: ActionType
    errors: any
    onChangeInput: (value: any, key: string) => void
    lookups: any
}

export const PersonalInformation: React.FC<Props> = ({ data, errors, formType, onChangeInput, lookups }) => {
    return (
        <>
            <h6>Personal Information</h6>
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.first_name}
                        onChange={(value: any) => onChangeInput(value, 'person/first_name')}
                        type='string' />
                }
                label='First Name'
                type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                value={data.person?.first_name}
                error={errors.person?.first_name}
                required
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.last_name}
                        onChange={(value: any) => onChangeInput(value, 'person/last_name')}
                        type='string' />
                }
                label='Last Name'
                type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                value={data.person?.last_name}
                error={errors.person?.last_name}
                required
            />
            <FormRow
                inputComponent={
                    <DatePicker
                        placeholder="MM/DD/YYYY"
                        value={data.person?.dob}
                        onChange={(value: any) => onChangeInput(value, 'person/dob')} />
                }
                label='Date of Birth'
                type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                value={getFormattedDateString(data.person?.dob)}
                error={errors.person?.dob}
                required
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.organization}
                        onChange={(value: any) => onChangeInput(value, 'person/organization')}
                        type='string' />
                }
                label='External Organization'
                type={formType}
                value={data.person?.organization}
                error={errors.person?.organization}
                required
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.person?.email}
                        onChange={(value: any) => onChangeInput(value, 'person/email')}
                        type='string' />
                }
                label='Email'
                type={formType}
                value={data.person?.email}
                error={errors.person?.email}
                required
            />
            <FormRow
                inputComponent={
                    <MaskedInput
                        value={data.person?.phone}
                        onChange={(value: any) => onChangeInput(value, 'person/phone')}
                        mask='(999)999-9999'
                    />
                }
                label='Phone Number'
                type={formType}
                value={data.person?.phone ? getFormattedPhoneNumber(data.person.phone) : 'None'}
                error={errors.person?.phone}
                required
            />
        </>
    );
}
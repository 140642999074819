import React, { useEffect, useState } from 'react'
import { Drawer, DrawerType, DrawerSize, GridContainer, LayoutColumn, Button, ButtonType, ButtonState } from '@mit/hui'
import { Alert, Col, Form } from 'react-bootstrap'
import { useFormContext, useAppContext, useLookupsContext, useAllocationContext, useSearchContext } from '../../../context'
import { initialSchedule } from '../../../api/model'
import { AllocationController, VaccineController } from '../../../api/controller'
import { getDateTimeString } from '../../../common/Util'
import { Banner } from '../Banner'
import CloneDeep from 'clone-deep'
//new
import { FormRow, AllocationFlags, AllocationFormModifyFields, AllocationSchedule } from '../'
import { Checkbox, Dropdown, Input, PeopleSearch } from '../../input'
import Loading from '../../Loading'

const controller = new AllocationController()

interface Props {
    placeholder?: any
}

export const AllocationForm: React.FC<Props> = ({ placeholder }) => {
    //contexts
    const { allocationForm, setAllocationForm }: any = useFormContext()
    const { auth }: any = useAppContext()
    const { allocationTypes, buildings, coreFacilities, departments, transportMethod }: any = useLookupsContext()
    const { setRefreshAllocation }: any = useAllocationContext()
    const { setRefreshAllocation: setSearchRefreshAllocation }: any = useSearchContext()
    //local state
    const [data, setData] = useState<any>(allocationForm.data)
    const [errors, setErrors] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [banners, setBanners]: any = useState({ id: null, messages: [] })
    const [hasAllocation, setHasAllocation] = useState<boolean>(false)
    const [vaccineHoldStatus, setVaccineHoldStatus] = useState<any>('')

    const formType = allocationForm.type

    const getFormTypeText = () => {
        switch (formType) {
            case 'NEW':
                return 'Create'
            case 'VIEW':
                return 'View'
            case 'EDIT':
                return 'Update'
            case 'DELETE':
                return 'Delete'
        }
    }

    const handleTimeChange = (event: any, item: any, key: any) => {
        let body: any = CloneDeep(data)
        let scheduleError = { ...errors.schedule }
        for (var x of body.schedule) {
            if (x.day_of_week === item.day_of_week) {
                if (key === 'varies') {
                    x[key] = event
                    x['start_time'] = ''
                    x['end_time'] = ''
                } else {
                    x[key] = event.id
                    if (key === 'start_time') {
                        x['end_time'] = ''
                    }
                }
                scheduleError[x.day_of_week] = ''
            }
        }
        setData(body)
        setErrors((state: any) => ({ ...state, noSchedule: '', schedule: scheduleError }))
        setBanners({ id: null })
    }

    const onChangeInput = (value: any, key: string) => {
        if (value === null) {
            value = []
        }
        if (key === 'buildings') value = value.slice(0, auth.max_buildings)

        if (key === 'cores') value = value.slice(0, auth.max_cores)

        setData((prev: any) => ({ ...prev, [key]: value }))
        clearError(key)
    }

    const onChangeAllBuildings = (checked: boolean) => {
        let buildings = checked ? [] : data.buildings

        setData((prev: any) => ({ ...prev, buildings: buildings, all_buildings: checked }))
        clearError('buildings')
    }

    const handleChange = (value: any, obj: any) => {
        debugger
    }

    const clearError = (key: string) => {
        setErrors((prev: any) => ({ ...prev, [key]: false }))
        setBanners({ id: null })
    }

    //form actions
    const onClose = () => {
        setAllocationForm({
            show: false,
            data: {},
        })
    }

    const delayClose = () => {
        setTimeout(clearForm, 1000)
    }

    const clearForm = () => {
        setBanners({ id: null })
        setErrors({})
        setHasAllocation(false)
        onClose()
    }

    const onValidateForm = (delayClose: boolean) => {
        let body = CloneDeep(data),
            errors: any = {
                schedule: {},
            }
        if (formType !== 'DELETE') {
            body.schedule = body.schedule.filter((item: any) => item.start_time || item.varies)

            if (!body.allocation_type) errors['allocation_type'] = true

            if (!body.department) errors['department'] = true

            if (!body.principal_investigator) errors['principal_investigator'] = true

            if (!body.person) errors['person'] = true

            // if (!body.preferred_hours)
            //     errors['preferred_hours'] = true;

            // if (!body.cores || body.cores.length === 0)
            //     errors['cores'] = true;

            if ((!body.buildings || body.buildings.length === 0) && !body.all_buildings) errors['buildings'] = true

            if (body.schedule.length) {
                for (var x of body.schedule) {
                    if (!x.end_time && !x.varies) {
                        errors.schedule[x.day_of_week] = 'Select an end time'
                    }
                }
            }

            let hasErrors = Object.keys(errors).filter((key: string) => key !== 'schedule').length > 0 || Object.keys(errors.schedule).length > 0
            setErrors(errors)

            if (!hasErrors) {
                onSubmit(body, delayClose)
            }
        } else {
            onSubmit(body, delayClose)
        }
    }

    const onSubmit = (body: any, bClose: boolean) => {
        console.log('body', body)
        setBanners({ id: null })
        setIsLoading(true)
        if (formType === 'NEW') {
            controller
                .createAllocation(body)
                .then((data) => {
                    setBanners({ id: 1, header: 'Success', text: `Allocation was successfully created`, type: 'success', messages: [] })
                    setIsLoading(false)
                    setRefreshAllocation((prev: boolean) => !prev)
                    setSearchRefreshAllocation((prev: boolean) => !prev)
                    setHasAllocation(false)
                    if (bClose) {
                        delayClose()
                    }
                })
                .catch((error) => {
                    setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                    setIsLoading(false)
                })
        } else if (formType === 'EDIT') {
            controller
                .updateAllocation(body.id, body)
                .then((data) => {
                    setBanners({ id: 1, header: 'Success', text: `Allocation was successfully updated`, type: 'success', messages: [] })
                    setIsLoading(false)
                    setRefreshAllocation((prev: boolean) => !prev)
                    setSearchRefreshAllocation((prev: boolean) => !prev)
                    if (bClose) {
                        delayClose()
                    }
                })
                .catch((error) => {
                    setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                    setIsLoading(false)
                })
        } else if (formType === 'DELETE') {
            controller
                .deleteAllocation(body.id)
                .then((data) => {
                    setBanners({ id: 1, header: 'Success', text: `Allocation was successfully deleted`, type: 'success', messages: [] })
                    setIsLoading(false)
                    setRefreshAllocation((prev: boolean) => !prev)
                    setSearchRefreshAllocation((prev: boolean) => !prev)
                    if (bClose) {
                        delayClose()
                    }
                })
                .catch((error) => {
                    setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                    setIsLoading(false)
                })
        }
    }

    const onChangePerson = (value: any) => {
        onChangeInput(value, 'person')
        setHasAllocation(false)
        controller.hasAllocation(value.id).then((data) => {
            if (data.has_allocation) {
                setHasAllocation(true)
            }
        })
    }

    const isOneTimeAccess = () => {
        return data.allocation_type?.id === 8
    }

    useEffect(() => {
        let schedule = CloneDeep(initialSchedule)
        let data = CloneDeep(allocationForm.data)

        if (data.schedule) {
            for (var x of schedule) {
                for (var i of data.schedule) {
                    if (x.day_of_week === i.day_of_week && (i.start_time || i.varies)) {
                        x.start_time = i.start_time ? i.start_time : ''
                        x.end_time = i.end_time ? i.end_time : ''
                        x.varies = i.varies
                    }
                }
            }
        }

        data.schedule = schedule
        setData(data)

        if (data?.person) {
            const controller = new VaccineController()
            setIsLoading(true)
            controller
                .getVaccineHoldStatus(data.person.id)
                .then((response) => {
                    setVaccineHoldStatus(response.status)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                    setIsLoading(false)
                } )
            }

    }, [allocationForm.data])

   
    return (
        <Drawer
            type={DrawerType.Right}
            size={DrawerSize.Large}
            show={allocationForm.show}
            themedColor='medical'
            // show={true}
            header={<h3>{getFormTypeText()} Allocation</h3>}
            footer={[]}
            onClose={clearForm}
            contents={
                <>
                    <Loading loaded={isLoading} />
                    <Banner banners={banners} setBanners={setBanners} />
                    <form className='allocation-form'>
                        <GridContainer showGutters={true}>
                            <LayoutColumn colSize={6}>
                                {/* <AutoComplete
                                    items={[]}
                                    name='test'
                                    searchOptions={{
                                        startIcon: 'search',
                                        startIconAccessibilityText: 'search',
                                        placeholderText: 'Search your list',
                                        type: TextboxType.IconStart,
                                        name: "search"
                                    }}
                                    errors={true}
                                    
                                />

                                <div className='mt-5'></div> */}
                                <FormRow
                                    inputComponent={
                                        <Dropdown
                                            options={allocationTypes}
                                            value={data.allocation_type}
                                            onChange={(value: any) => onChangeInput(value, 'allocation_type')}
                                        />
                                    }
                                    label='Allocation Type'
                                    subLabel={
                                        <a href={process.env.REACT_APP_REQUIREMENTS_URL} target='_blank' rel='noopener noreferrer'>
                                            more Info
                                        </a>
                                    }
                                    type={formType}
                                    value={data.allocation_type}
                                    error={errors.allocation_type}
                                    required
                                />
                                <FormRow
                                    inputComponent={
                                        <Dropdown
                                            options={departments}
                                            value={data.department}
                                            onChange={(value: any) => onChangeInput(value, 'department')}
                                        />
                                    }
                                    label='Department'
                                    type={formType}
                                    value={data.department}
                                    error={errors.department}
                                    required
                                />
                                <FormRow
                                    inputComponent={
                                        <PeopleSearch
                                            value={data.principal_investigator}
                                            onChange={(value: any) => onChangeInput(value, 'principal_investigator')}
                                        />
                                    }
                                    label='PI / Supervisor'
                                    type={formType}
                                    value={data.principal_investigator}
                                    error={errors.principal_investigator}
                                    required
                                />
                                <FormRow
                                    inputComponent={<PeopleSearch value={data.person} onChange={onChangePerson} />}
                                    label='Person'
                                    type={formType}
                                    value={data.person}
                                    error={errors.person}
                                    required
                                />
                                <FormRow
                                    inputComponent={
                                        <Input
                                            value={data.preferred_hours}
                                            onChange={(value: any) => onChangeInput(value, 'preferred_hours')}
                                            type='number'
                                        />
                                    }
                                    label='Weekly Hours'
                                    type={formType}
                                    value={data.preferred_hours}
                                    error={errors.preferred_hours}
                                />
                                <FormRow
                                    inputComponent={
                                        <Dropdown
                                            options={coreFacilities}
                                            isMulti
                                            value={data.cores}
                                            onChange={(value: any) => onChangeInput(value, 'cores')}
                                        />
                                    }
                                    label='Core Facilities'
                                    subLabel={`(max ${auth.max_cores})`}
                                    type={formType}
                                    value={data.cores}
                                    error={errors.cores}
                                />
                                {(auth.roles.essential.edit || auth.roles.essential.view) && (
                                    <FormRow
                                        inputComponent={
                                            <Checkbox
                                                checked={data.all_buildings}
                                                onChange={onChangeAllBuildings}
                                                disabled={formType === 'VIEW' || formType === 'DELETE' || !auth.roles.essential.edit}
                                            />
                                        }
                                        label='All Buildings'
                                        type={'EDIT'}
                                        value={data.all_buildings}
                                        error={errors.all_buildings}
                                    />
                                )}
                                <FormRow
                                    inputComponent={
                                        <Dropdown
                                            options={buildings}
                                            isMulti
                                            value={data.buildings}
                                            disabled={data.all_buildings}
                                            onChange={(value: any) => onChangeInput(value, 'buildings')}
                                        />
                                    }
                                    label='Buildings'
                                    subLabel={`(max ${auth.max_buildings})`}
                                    type={formType}
                                    value={data.all_buildings || data.buildings}
                                    error={errors.buildings}
                                    required
                                />
                                <FormRow
                                    inputComponent={
                                        <Dropdown
                                            options={transportMethod}
                                            value={data.transport_method}
                                            onChange={(value: any) => onChangeInput(value, 'transport_method')}
                                        />
                                    }
                                    label='Transport Method'
                                    type={formType}
                                    value={data.transport_method}
                                    error={errors.transport_method}
                                />

                                <FormRow
                                    inputComponent={<AllocationFlags auth={auth} data={data} formType={formType} onChange={onChangeInput} />}
                                    label='Allocation Flags'
                                    type={'EDIT'}
                                />

                                {formType !== 'NEW' && (
                                <FormRow
                                    inputComponent={<></>}
                                    label='Vaccine Hold'
                                    type={formType}
                                    value={vaccineHoldStatus}
                                    error={errors.vaccineHoldStatus}
                                />      
                                )}

                                {((!data.is_essential && !data.is_voluntary) || !data.is_campus_access_required) && (
                                    <Col sm={10} className='mt-3 required-flag-text'>
                                        <h6>
                                            In order to be granted access, the staff member must be flagged as willing to return to campus AND that
                                            they need to be on campus.
                                        </h6>
                                    </Col>
                                )}
                                <AllocationFormModifyFields data={data} formType={formType} />
                            </LayoutColumn>
                            <LayoutColumn colSize={6}>
                                {(data.allocation_type ? data.allocation_type.id !== 8 : true) && (
                                    <FormRow
                                        inputComponent={
                                            <AllocationSchedule
                                                data={data}
                                                errors={errors}
                                                formType={formType}
                                                handleChange={handleChange}
                                                handleTimeChange={handleTimeChange}
                                            />
                                        }
                                        label='Schedule'
                                        labelPlacement='top'
                                        type={'EDIT'}
                                        fullWidth
                                    />
                                )}

                                <FormRow
                                    inputComponent={
                                        <Form.Control
                                            as='textarea'
                                            rows={3}
                                            value={data.comments}
                                            onChange={(event: any) => onChangeInput(event.target.value, 'comments')}
                                        />
                                    }
                                    label='Comments'
                                    type={formType}
                                />
                            </LayoutColumn>

                            <LayoutColumn colSize={5}>
                                {hasAllocation && (
                                    <>
                                        {isOneTimeAccess() ? (
                                            <Alert variant='warning' className='mb-3'>
                                                The person has an existing allocation, you may still choose to allocate.
                                            </Alert>
                                        ) : (
                                            <Alert variant='warning' className='mb-3'>
                                                The person has an existing allocation, you may still choose to allocate.
                                            </Alert>
                                        )}
                                    </>
                                )}
                            </LayoutColumn>

                            {formType !== 'VIEW' && (
                                <LayoutColumn colSize={12}>
                                    <Button
                                        type={ButtonType.Primary}
                                        text={`${getFormTypeText()} and stay`}
                                        onClick={onValidateForm}
                                        state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                    />
                                    &nbsp;&nbsp;
                                    <Button
                                        type={ButtonType.Ghost | ButtonType.Primary}
                                        text={`${getFormTypeText()} and close`}
                                        onClick={() => onValidateForm(true)}
                                        state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                    />
                                </LayoutColumn>
                            )}
                            {formType !== 'NEW' && (
                                <LayoutColumn colSize={12}>
                                    <div className='mt-5'>Created By: {data.created_by}</div>
                                    <div>Created On: {getDateTimeString(data.created_on, true)}</div>
                                    <div>Updated By: {data.updated_by}</div>
                                    <div>Updated On: {getDateTimeString(data.updated_on, true)}</div>
                                </LayoutColumn>
                            )}
                        </GridContainer>
                    </form>
                </>
            }
        />
    )
}

import React, { useEffect, useState } from 'react'
import { UniversalHoldController } from '../../api/controller'
import Loading from '../Loading'
import { getDateTimeString, convertToEst, getFormattedDateString } from '../../common/Util'
import { useFormContext, useAllocationContext, useSearchContext } from '../../context/'
import { DatePicker } from '../input'
import { Banner, FormRow } from './'
import Tooltip from '../Tooltip'
import { Drawer, Button, DrawerType, DrawerSize, ButtonState, NavBar, NavItemProps, NavBarTypes, NavItemState } from '@mit/hui'
import { Row, Col } from 'react-bootstrap'

interface Props {}

const controller = new UniversalHoldController()

export const UniversalHold: React.FC<Props> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [banners, setBanners]: any = useState({ id: null, messages: [] })
    const [state, setState]: any = useState({})
    const [errors, setErrors]: any = useState({})
    const [holds, setHolds]: any = useState([])
    const [tabs, setTabs]: any = useState([])
    const { residentHold, setResidentHold }: any = useFormContext()
    const { setRefreshAllocation }: any = useAllocationContext()
    const { setRefreshAllocation: setSearchRefreshAllocation }: any = useSearchContext()
    const [data, setData] = useState<any>([])

    const validateForm = () => {
        let errors: any = {}

        if (!state.hold_start_date) errors.hold_start_date = 'Required'
        if (!state.hold_end_date) errors.hold_end_date = 'Required'

        let start_date = new Date(state.hold_start_date)
        let end_date = new Date(state.hold_end_date)

        if (start_date > end_date) errors.hold_end_date = 'Invalid'

        setErrors(errors)
        return Object.keys(errors).length === 0
    }

    const createOverride = () => {
        let valid = validateForm()
        if (valid) {
            setIsLoading(true)
            setBanners({ id: null, messages: [] })
            const body = {
                ...state,
                mit_id: data.id,
                hold_status: 'A',
            }

            let query: Promise<any>

            if (!state.hold_guid) {
                query = controller.createHold(data.id, body)
            } else {
                query = controller.updateHold(data.id, body)
            }

            query
                .then((data) => {
                    setBanners({
                        id: 1,
                        header: 'Success',
                        text: state.hold_guid ? 'Hold Updated' : 'Hold Created',
                        type: 'success',
                        messages: [],
                    })
                    refreshOverride()
                    refreshAllocations()
                })
                .catch((error) => {
                    setBanners({
                        id: 1,
                        header: 'Error',
                        text: error,
                        type: 'danger',
                        messages: [],
                    })
                    setIsLoading(false)
                })
        }
    }

    const loadOverride = (guid: string) => {
        let hold = holds.filter((hold: any) => hold.hold_guid === guid)[0]

        setState(hold)
    }

    const deleteOverride = (hold: any) => {
        setIsLoading(true)
        setBanners({ id: null, messages: [] })

        controller
            .deleteHold(hold.hold_guid, { hold_type_id: hold.hold_type_id })
            .then((data: any) => {
                setBanners({
                    id: 1,
                    header: 'Success',
                    text: 'Hold Deleted',
                    type: 'success',
                    messages: [],
                })
                refreshOverride()
                refreshAllocations()
            })
            .catch((error) => {
                setBanners({
                    id: 1,
                    header: 'Error',
                    text: error,
                    type: 'danger',
                    messages: [],
                })
                setIsLoading(false)
            })
    }

    const refreshAllocations = () => {
        setRefreshAllocation((prev: any) => !prev)
        setSearchRefreshAllocation((prev: any) => !prev)
    }

    const refreshOverride = () => {
        if (data.id) {
            setIsLoading(true)
            controller
                .getHold(data?.id)
                .then((holds) => {
                    holds = holds.filter((hold: any) => hold.hold_status === 'A' && hold.hold_type_id === state.hold_type_id)
                    setHolds(holds)

                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                })
        }
    }

    const handleClose = () => {
        setBanners({ id: null, messages: [] })
        setResidentHold({
            show: false,
            data: {},
        })
        setState({})
        setErrors({})
    }

    const handleChange = (value: string, key: string) => {
        setState((prev: any) => {
            return {
                ...prev,
                [key]: value,
            }
        })
        setErrors((prev: any) => ({ ...prev, [key]: '' }))
    }

    useEffect(() => {
        setData(residentHold.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [residentHold])

    useEffect(() => {
        refreshOverride()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, state.hold_type_id])

    useEffect(() => {
        setIsLoading(true)
        if (data?.id) {
            controller
                .getHoldTypes(data?.id)
                .then((types) => {
                    types = types.filter((itm: any) => itm.allow_manual && data.manage_holds && data.manage_holds[itm.name])

                    let navItemProps: NavItemProps[] = types.map((itm: any, index: number) => {
                        return {
                            icon: '',
                            iconOnly: false,
                            text: itm.name,
                            onClick: () => {
                                handleChange(itm.id, 'hold_type_id')
                                handleChange(itm.name, 'hold_type_name')

                                navItemProps.map((itm, idx) => {
                                    navItemProps[idx].state = NavItemState.None

                                    return itm
                                })

                                navItemProps[index].state = NavItemState.Active
                            },
                            state: index === 0 ? NavItemState.Active : NavItemState.None,
                        }
                    })

                    setTabs(navItemProps)

                    if (types.length > 0) {
                        handleChange(types[0].id, 'hold_type_id')
                        handleChange(types[0].name, 'hold_type_name')
                    }

                    setIsLoading(false)
                })
                .catch((error) => {
                    setBanners({
                        id: 1,
                        header: 'Error',
                        text: error,
                        type: 'danger',
                        messages: [],
                    })

                    setIsLoading(false)
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id])

    return (
        <Drawer
            show={residentHold.show}
            onClose={handleClose}
            type={DrawerType.Right}
            size={DrawerSize.Medium}
            themedColor='medical'
            header={
                <div className='d-flex flex-column'>
                    <h3>Hold</h3>
                    <h5 className='mt-2'>
                        {data.name} ({data.krb_name}){' '}
                    </h5>
                </div>
            }
            footer={[]}
            contents={
                <>
                    <Loading loaded={isLoading} />
                    <Banner banners={banners} setBanners={setBanners} />

                    <NavBar tabs={tabs} type={NavBarTypes.Text} />

                    <div className='card col-8 mx-auto p-3 mt-3'>
                        <Row className='mb-3'>
                            <Col>
                                <h6>A {state.hold_type_name} hold will pause access and notifications.</h6>
                            </Col>
                        </Row>

                        <FormRow
                            inputComponent={
                                <DatePicker
                                    placeholder='MM/DD/YYYY'
                                    value={state.hold_start_date ? state.hold_start_date : null}
                                    onChange={(value: string) => handleChange(value, 'hold_start_date')}
                                />
                            }
                            label='Start Date'
                            type='EDIT'
                            value={state.hold_start_date}
                            error={errors.hold_start_date}
                            required
                        />
                        <FormRow
                            inputComponent={
                                <DatePicker
                                    placeholder='MM/DD/YYYY'
                                    value={state.hold_end_date ? state.hold_end_date : null}
                                    onChange={(value: string) => handleChange(value, 'hold_end_date')}
                                />
                            }
                            label='End Date'
                            type='EDIT'
                            value={state.hold_end_date}
                            error={errors.hold_end_date}
                            required
                        />

                        <div>
                            {!state.hold_guid ? (
                                <Button onClick={createOverride} text='Create Hold' state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} />
                            ) : (
                                <Button onClick={createOverride} text='Update Hold' state={isLoading ? ButtonState.Disabled : ButtonState.Enabled} />
                            )}
                        </div>
                    </div>
                    <Row className='mt-3'>
                        <Col>
                            <h6>Created by</h6>
                        </Col>
                        <Col>
                            <h6>Created on</h6>
                        </Col>
                        <Col>
                            <h6>Start Date</h6>
                        </Col>
                        <Col>
                            <h6>End Date</h6>
                        </Col>
                        <Col>
                            <h6>Type</h6>
                        </Col>
                        <Col></Col>
                    </Row>
                    {holds.map((hold: any, index: number) => (
                        <Row className='mt-3' key={index}>
                            <Col>{hold.created_by}</Col>
                            <Col>{getDateTimeString(convertToEst(hold.created_on))}</Col>
                            <Col>{getFormattedDateString(hold.hold_start_date)}</Col>
                            <Col>{getFormattedDateString(hold.hold_end_date)}</Col>
                            <Col>{hold.hold_type_name}</Col>
                            <Col>
                                <Tooltip text={'Edit'}>
                                    <i onClick={() => loadOverride(hold.hold_guid)} className={`fas fa-edit icon-edit ml-2 icon`}></i>
                                </Tooltip>
                                <Tooltip text={'Delete'}>
                                    <i onClick={() => deleteOverride(hold)} className={`fas fa-times-circle icon-clear ml-2 icon`}></i>
                                </Tooltip>
                            </Col>
                        </Row>
                    ))}

                    {/* <h6 className='mt-3 note-text'>Allow up to 30 minutes for building access to take affect.</h6> */}
                </>
            }
        />
    )
}

import React, { useState, createContext } from 'react';

import { ListItem } from '../types/Lookup';

export const SearchContext = createContext({

});

interface Props {
    children: React.ReactNode
}


export const SearchProvider: React.FC<Props> = ({ children }) => {
    const [listItems, setListItems] = useState<ListItem[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [cardSwipes, setCardSwipes] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [isLoading, setIsLoading] = useState<any>({ list: false, items: false, card_swipes: false });
    const [selectedTab, setSelectedTab] = useState<string>('allocation');
    const [canViewCardSwipes, setCanViewCardSwipes] = useState<boolean>(false);
    const [refreshAllocation, setRefreshAllocation] = useState<boolean>(false);
    const [tableType, setTableType] = useState<'default' | 'compliance'>('default')

    const exportValue = {
        listItems,
        setListItems,
        items,
        setItems,
        selectedItem,
        setSelectedItem,
        isLoading,
        setIsLoading,
        cardSwipes,
        setCardSwipes,
        selectedTab,
        setSelectedTab,
        canViewCardSwipes,
        setCanViewCardSwipes,
        refreshAllocation,
        setRefreshAllocation,
        tableType,
        setTableType
    }

    return (
        <SearchContext.Provider value={exportValue}>
            {children}
        </SearchContext.Provider>
    )

}


export const useSearchContext = () => {
    const context = React.useContext(SearchContext);
    if (context === undefined) {
        throw new Error('useSearchContext must be used within a AppProvider');
    }
    return context;
};


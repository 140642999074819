import React, {useState, useEffect} from 'react';
import { ActionList, Text, Button, ButtonState, TemplateModal, TemplateModalSize, TemplateModalAlignment, Alert } from '@mit/hui'
import { VaccineController } from '../api/controller'
import moment from 'moment'

interface Props {
    selectedPerson: any
    roles:any
}

const controller = new VaccineController;
//TODO: remove default ID
export const PendingMedicalTests: React.FC<Props> = ({ selectedPerson, roles }) => {
    
    const [loading, setLoading] = useState<boolean>(true)
    const [tests, setTests] = useState<any>([])
    const [mappedTests, setMappedTests] = useState<any>([])

    const [itemToRemove, setItemToRemove] = useState<any>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [showWarning, setShowWarning] = useState<boolean>(false)
    const [notification, setNotification] = useState<any>(null)

    const init = async () => {
        setLoading(true)
        let res = await controller.getPendingTests(selectedPerson.id);
        setTests(res)
        setLoading(false)
    }

    const removeItem = async () => {
        setDeleting(true)
        await controller.cancelPendingTests(selectedPerson.id, itemToRemove.accessionNumber )
        .then((res)=>{
            setShowWarning(false)
            setNotification(null)
            init()
        }).catch((err)=>{
            let error = JSON.parse(err)
            setNotification({
                text: error.message
            })
        })
        setDeleting(false)
    }

    const mapTests = () => {
        let mapped = [...tests].map((item:any)=> {
            let d = new Date(item.testDate)
           return { 
                text: moment(d).format('MM/D/YYYY'),
                tertiaryText: item.accessionNumber,
                actions : [
                    {
                        icon: "trash",
                        text: "",
                        type: 32,
                        onClick: () => { setItemToRemove(item); setShowWarning(true) },
                        state: 0,
                    }
                ]
            }
        })
        setMappedTests(mapped)
    }

    const closeModal = () => {
        setShowWarning(false);
        setItemToRemove(null);
        setNotification(null);
        setDeleting(false)
    };

    useEffect(() => {
       init()
    }, []);

    useEffect(() => {
        if(tests){
            mapTests()
        }
    }, [tests])

    return (<>

        <Text content={`Pending Medical Tests for: ${selectedPerson.name}`} />
        <ActionList compact={true} selectable={false} isEmpty={tests.length === 0} isLoading={loading} items={mappedTests} />
        <TemplateModal
            name=""
            show={showWarning}
            onClose={ ()=> closeModal()}
            noExternalClose
            bodyAlignment={TemplateModalAlignment.Center}
            header={<h2>Cancelling Pending Medical Test</h2>}
            body={<>
                <p className="mt-3">Are you sure you want to cancel {itemToRemove ? itemToRemove.accessionNumber : ""} ? </p>
                {notification &&
                    <Alert 
                        text={notification.text}
                        type="error"
                        icon={'exclamation-triangle'}
                    />
                }
            </>}
            footer={ <Button state={ deleting? ButtonState.Disabled : ButtonState.Enabled } isBusy={deleting} text="Delete" onClick={() => removeItem() } /> }
            />
        </>
    );
}

import React, { Fragment } from 'react';

import ValidationError from '../ValidationError';

import InputMask from 'react-input-mask';


interface Props {
    onChange: any
    placeholder?: string
    value: any
    mask: string
    className?: string
    errorText?: string
}

export const MaskedInput: React.FC<Props> = ({ className, errorText, onChange, placeholder, value, mask }) => {

    const onValueChange = (e: any) => {
        let value = e.target.value;

        if (value) {
            value = value.split('(').join('').split(')').join('').split('-').join('').split('_').join('');;
        }

        if (onChange) {
            onChange(value);
        }
    }

    return (
        <Fragment>
            <InputMask placeholder={placeholder} mask={mask} maskChar={'_'} value={value} onChange={onValueChange} className={'input-mask input ' + (className ? className : '')} />
            <ValidationError text={errorText} />
        </Fragment>
    );

}


import React, { useState } from 'react'
import Tooltip from '../../Tooltip'
import { AllocationMoreActions, Action } from './AllocationMoreActions';
import { useFormContext, useAllocationContext, useSearchContext } from '../../../context';
import { AllocationController } from '../../../api/controller';

interface Props {
    item: any
}

export const AllocationActions: React.FC<Props> = ({ item }) => {
    const { setAllocationForm }: any = useFormContext()
    const { setRefreshAllocation }: any = useAllocationContext();
    const { setRefreshAllocation: setSearchRefreshAllocation }: any = useSearchContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const controller = new AllocationController();

    const handleClick = (type: string) => {
        setAllocationForm({
            show: true,
            type: type,
            data: item
        })
    }

    const updateDesignation = () => {
        setIsLoading(true);

        var body = { ...item, ramp_down_access: !item.ramp_down_access };

        controller.updateAllocation(body.id, body)
            .then(data => {

                setIsLoading(false);
                setRefreshAllocation((prev: boolean) => !prev);
                setSearchRefreshAllocation((prev: boolean) => !prev);
            })
            .catch(error => {
                setIsLoading(false);
            })
    }


    const actions: Action[] = [
        {
            name: 'View',
            onClick: () => handleClick('VIEW'),
            icon: 'info-circle',
            auth_role: null,
            class: 'icon-info'
        },
        {
            name: 'Edit',
            onClick: () => handleClick('EDIT'),
            icon: 'edit',
            auth_role: 'can_edit',
            class: 'icon-edit ml-2'
        },
        {
            name: 'Delete',
            onClick: () => handleClick('DELETE'),
            icon: 'times-circle',
            auth_role: 'can_edit',
            class: 'icon-clear ml-2'
        },
        {
            name: 'Toggle designated personnel setting',
            onClick: () => updateDesignation(),
            icon: !isLoading ? 'universal-access' : 'spinner',
            auth_role: 'can_edit',
            class: !isLoading ? (!item.ramp_down_access ? 'icon-universal-access ml-2' : 'icon-universal-access icon-pending ml-2' ) : 'fa-spin ml-2'
        }
    ]

    return (
        <div>
            {actions.map((action: Action) => {
                if (item[action.auth_role] || !action.auth_role) {
                    return <Tooltip text={action.name}>
                        <i
                            onClick={action.onClick}
                            className={`fas fa-${action.icon} ${action.class} icon`}></i>
                    </Tooltip>
                }
                else return ''
            })}
            <AllocationMoreActions
                item={item}
                icon='ellipsis-h'
            />

        </div>
    );
}
import React, { useEffect,  } from 'react'
import { useFormContext, useAppContext, useLookupsContext, useAllocationContext, useSearchContext } from '../../context'
import { AllocationTable, CardSwipesTable } from '../table'
import { AllocationController } from '../../api/controller'

import { useParams } from 'react-router-dom'
import { VaccineStatus } from '../VaccineStatus'
import { PendingMedicalTests } from '../PendingMedicalTests'

const allocationController = new AllocationController()

interface Props {
    context: any
}

export const SearchDetail: React.FC<Props> = ({ context }) => {
    const {
        items,
        setItems,
        selectedItem,
        // setSelectedItem,
        isLoading,
        setIsLoading,
        // cardSwipes,
        // setCardSwipes,
        selectedTab,
        setSelectedTab,
        refreshAllocation,
    }: any = context
    const { auth }: any = useAppContext();

    
    

    console.log(" allocations/authorization data", auth.roles.pending_tests);

    const { assignment_type_id }: any = useParams()

    useEffect(() => {
        if (selectedItem.hasOwnProperty('id')) {
            setSelectedTab('allocation')
            setIsLoading((state: any) => ({ ...state, card_swipes: true }))

            //   cardSwipesController
            //     .getSwipes(selectedItem.id)
            //     .then((data) => {
            //       setCardSwipes(data)
            //       // setCanViewCardSwipes(true);
            //       setIsLoading((prev: any) => ({ ...prev, card_swipes: false }))
            //     })
            //     .catch((error) => {
            //       setCardSwipes([])
            //       setIsLoading((prev: any) => ({ ...prev, card_swipes: false }))
            //       // setCanViewCardSwipes(true);
            //     })

            // holdController.getHold(selectedItem.id)
            //     .then((data) => {
            //         setSelectedItem((prev: any) => ({ ...prev, auth: { ...prev.auth, residentHold: true } }))

            //     })
            //     .catch((error) => {
            //         setSelectedItem((prev: any) => ({ ...prev, auth: { ...prev.auth, residentHold: false } }))
            //     });

            //   emergencyOverrideController
            //     .getOverride(selectedItem.id)
            //     .then((data) => {
            //       setSelectedItem((prev: any) => ({ ...prev, auth: { ...prev.auth, emergencyOverride: true } }))
            //     })
            //     .catch((error) => {
            //       setSelectedItem((prev: any) => ({ ...prev, auth: { ...prev.auth, emergencyOverride: false } }))
            //     })
        }

        //eslint-disable-next-line
    }, [selectedItem.id])

    useEffect(() => {
        console.log('reload')

        if (selectedItem.hasOwnProperty('id')) {
            let kerberos_id = selectedItem.krb_name

            setIsLoading((state: any) => ({ ...state, items: true }))

            allocationController.getAllocations(assignment_type_id, assignment_type_id, null, null, kerberos_id).then((data) => {
                setItems(data.allocations)
                setIsLoading((state: any) => ({ ...state, items: false }))
            })
        }

        //eslint-disable-next-line
    }, [selectedItem.id, refreshAllocation])

    if (!selectedItem.id && !isLoading.items) {
        return <h2 className='text-center'>Search for person or company</h2>
    }

    // return <AllocationTable items={items} context={context} isLoading={isLoading.items} />;
   
    if (selectedTab === 'allocation') {
        return (
            <div className='search-container'>
                <AllocationTable items={items} context={context} isLoading={isLoading.items} />
            </div>
        )
    } else if (selectedTab === 'card_swipes') {
        return (
            <div className='search-container'>
                <CardSwipesTable id={selectedItem.id} />
            </div>
        )
    } else if (selectedTab === 'vaccine_status') {
        return (
            <div className='search-container'>
                <VaccineStatus id={selectedItem.id} />
            </div>
        )
    }
    else if (selectedTab === 'pending_tests') {
        return (
            <div className='search-container'>
                <PendingMedicalTests selectedPerson={selectedItem} roles={auth.roles} />
            </div>
        )
    }

    return null
}

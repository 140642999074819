import { EventBus } from '@mit/aws-react'
import { BaseController } from '../api/controller'
import { useAppContext, useLookupsContext } from '../context'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const { initializeApp: initAppState }: any = useAppContext()
  const { setAllocationTypes, setBuildings, setCoreFacilities, setDepartments, setTransportMethod }: any = useLookupsContext()

  const initializeApp = async (): Promise<void> => {
    // Simulate api call. Don't use timeout

    // You can access easy peasy state in this hook

    var loggedInUser = ''
    const baseController = new BaseController()

    baseController
      .getUserAuth()
      .then((data) => {
        initAppState({ ...data, loggedInUser: loggedInUser })
        setBuildings(data.lookups.buildings)
        setDepartments(data.lookups.departments)
        setAllocationTypes(data.lookups.allocation_types)
        setCoreFacilities(data.lookups.core_facilities)
        setTransportMethod(data.lookups.transport_methods)

        EventBus.dispatch('APP_INITIALIZED', {
          isLoading: false,
          hasAccess: data.authorized,
        })
      })
      .catch((error) => {
        EventBus.dispatch('APP_INITIALIZED', {
          isLoading: false,
          hasAccess: false,
        })
      })
  }

  return { initializeApp }
}

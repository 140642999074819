import React from 'react'

import { AllocationForm, AllocationUploadForm, SponsoredAccountForm, SponsoredAccountUploadForm, EmergencyOverrideForm, ResendSponsorAccountEmail } from '../forms';
import { UniversalHold } from '../forms/UniversalHold';



interface Props {
    placeholder?: any
}

export const Forms: React.FC<Props> = ({ placeholder }) => {



    return (
        <>
            <AllocationForm />
            <AllocationUploadForm />
            <SponsoredAccountForm />
            <SponsoredAccountUploadForm />
            <ResendSponsorAccountEmail />
            <EmergencyOverrideForm />
            
            <UniversalHold />
        </>
    );
}
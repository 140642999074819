import React, { useEffect } from 'react'

import { SponsoredAccountTable } from '../table';

interface Props {
    context: any
}

export const SponsoredAccountDetail: React.FC<Props> = ({ context }) => {
    const { items, setItems, selectedItem, isLoading }: any = context;


    useEffect(() => {
        if (selectedItem.hasOwnProperty('id')) {
            setItems(selectedItem.items);
        }

        //eslint-disable-next-line
    }, [selectedItem])



    return <SponsoredAccountTable
        items={items || []}
        context={context}
        isLoading={isLoading.items} />;


}
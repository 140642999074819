import React, { Fragment } from 'react'

import { CalendarInput } from '@mit/hui'
import { withComponent } from '../../common/WithComponent'

interface Props {
    onChange: any
    placeholder?: string
    value: any
    className?: string
    errorText?: string
}

export const DatePicker: React.FC<Props> = ({ className, errorText, onChange, placeholder, value }) => {
    const onValueChange = (date: any) => {
        let dateString = null
        if (date !== null) {
            dateString = date
        }

        if (onChange) {
            onChange(dateString)
        }
    }

    let valueProps = {}
    if (value) {
        valueProps = { value: value }
    }

    if (value === '0000-00-00') {
        valueProps = {}
    }

    const CalendarInputData = withComponent(CalendarInput)

    return (
        <Fragment>
            <CalendarInputData
                id={'date'}
                isRangeSelected={false}
                isMultiSelect={false}
                onChange={onValueChange}
                {...valueProps}
                placeholder={placeholder}
            />
        </Fragment>
    )
}

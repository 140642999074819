import React, { useState, createContext } from 'react';


import { SponsoredAccountProvider } from './SponsoredAccountContext';
import { SearchProvider } from './SearchContext';
import { FormProvider } from './FormContext';
import { LookupsProvider } from './LookupsContext';
import { AllocationProvider } from './AllocationContext';



export const AppContext = createContext({});

interface Props {
	children: React.ReactNode;
}


export const AppProvider: React.FC<Props> = ({ children }) => {
	const [auth, setAuth]: any = useState({
		loggedInUser: '',
		authorized: false,
		max_cores: 7,
		max_buildings: 5,
		roles: {
			1: { view: false, edit: false },
			2: { view: false, edit: false },
			3: { view: false, edit: false },
			6: { view: false, edit: false },
			8: { view: false, edit: false },
			9: { view: false, edit: false },
			10: { view: false, edit: false },
			11: { view: false, edit: false },
			people_search: { view: false, edit: false },
			vcs_admin: { view: false, edit: false },
		}
	});
	const [profile, setProfile]: any = useState({});
	const [profilePicture, setProfilePicture]: any = useState({});
	const [refreshAllocations, setRefreshAllocations] = useState(0);
	const [isLoading, setIsLoading]: any = useState({
		buildings: true,
		departments: true,
		allocationTypes: true,
		coreFacilities: true,
		transportMethod: true
	});



	const initializeApp = (data: any) => {
		setAuth(data);
		// setPages(data.roles.map((item: any) => (cloneDeep(defaultPageState))))
	}
	const initializeProfile = (data: any) => {
		setProfile(data);
	}

	const initializeProfilePicture = (data: any) => {
		let url = URL.createObjectURL(data);
		setProfilePicture(url);
	}

	const exportValue = {
		initializeApp,
		initializeProfile,
		initializeProfilePicture,
		auth,
		profile,
		setProfile,
		profilePicture,
		setProfilePicture,
		isLoading,
		setIsLoading,
		refreshAllocations,
		setRefreshAllocations
	}

	// const PiProvider = TypesContext[0].provider;
	// const PiContext = TypesContext[0].context;


	return (
		<AppContext.Provider value={exportValue} >

			<SponsoredAccountProvider>
				<SearchProvider>
					<FormProvider>
						<LookupsProvider>
							<AllocationProvider>
								{children}
							</AllocationProvider>
						</LookupsProvider>
					</FormProvider>
				</SearchProvider>
			</SponsoredAccountProvider>

		</AppContext.Provider >
	)
}

export const useAppContext = () => {
	const context = React.useContext(AppContext);
	if (context === undefined) {
		throw new Error('useAppContext must be used within a AppProvider');
	}
	return context;
};

// const getContext = (id: any) => {
//     switch (id) {
//         case '1': return LabResearchContext;
//         case '2': return CoreContext;
//         case '3': return AdminContext;
//         case '6': return VendorSupplierContext;
//         case '8': return OneTimeAccessContext;
//         case '9': return LincolnLabContext;
//         case '12': return NonLaboratoryResearchContext;
//         case 'search': return SearchContext;
//         case 'sponsoredAccount': return SponsoredAccountContext;
//     }
//     return LabResearchContext;
// }


// export const useTypesContext = (id: any) => {
//     const context = React.useContext(getContext(id));
//     if (context === undefined) {
//         throw new Error('usePiContext must be used within a PiProvider')
//     }
//     return context
// }







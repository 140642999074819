import React from 'react'
import { LoggedInUser } from '../nav/LoggedInUser'
import { MainNavBar } from '../nav/MainNavBar'

import { AllocationMasterDetail } from '../AllocationMasterDetail/AllocationMasterDetail'
import { SponsoredAccountMasterDetail } from '../SponsoredAccountMasterDetail/SponsoredAccountMasterDetail'
import { SearchMasterDetail } from '../SearchMasterDetail/SearchMastDetail'
import { Forms } from '../container/Forms'

import { Switch, Route, Redirect } from 'react-router-dom'

import { AtlasPage, NavItemProps } from '@mit/hui'
import { getEnv } from '../../common'

interface Props {
    placeholder?: any
    history?: any
}

export const Home: React.FC<Props> = ({ history }) => {
    const goHelp = () => {
        window.location.href = 'mailto:covidapps-help@mit.edu'
    }

    const goReleaseNotes = () => {
        history.push('/release-notes')
    }

    const utilNavItems: NavItemProps[] = [
        { icon: 'question-circle', text: 'Help', iconOnly: false, onClick: () => goHelp() },
        {
            icon: 'bell',
            text: "What's new!",
            iconOnly: false,
            onClick: () => {
                goReleaseNotes()
            },
        },
    ]

    return (
        <>
            <AtlasPage
                gaId={''}
                theme={'medical'}
                utilityNavigation={utilNavItems}
                environment={getEnv()}
                name={'Covid Access'}
                navigation={<MainNavBar />}
                profile={<LoggedInUser />}
                content={
                    <>
                        <Switch>
                            <Route path={'/allocation/:assignment_type_id?'} component={AllocationMasterDetail} />
                            <Route path={'/sponsored-accounts'} component={SponsoredAccountMasterDetail} />
                            <Route path={'/search'} component={SearchMasterDetail} />
                            <Route path='/' render={() => <Redirect to='/search' />} exact={true} />
                        </Switch>
                    </>
                }
            />
            <Forms />
        </>
    )
}

import React from 'react'
import { NavBar, NavBarTypes, NavItemState, NavItemProps } from '@mit/hui'
import { useAppContext, useSearchContext } from '../../context'

interface Props {
    placeholder?: any
}

export const SearchNav: React.FC<Props> = ({ placeholder }) => {
    const { auth }: any = useAppContext()
    const { selectedTab, setSelectedTab }: any = useSearchContext()

    let tabs: NavItemProps[] = [
        {
            text: 'Allocation',
            icon: '',
            iconOnly: false,
            onClick: () => setSelectedTab('allocation'),
            state: selectedTab === 'allocation' ? NavItemState.Active : NavItemState.None,
        },
    ]

    if (auth.roles?.card_swipes?.view || auth.roles?.card_swipes?.edit) {
        tabs.push({
            text: 'Card Swipes',
            icon: '',
            iconOnly: false,
            onClick: () => setSelectedTab('card_swipes'),
            state: selectedTab === 'card_swipes' ? NavItemState.Active : NavItemState.None,
        })
    }

    if (auth.roles?.vaccine_status?.view || auth.roles?.vaccine_status?.edit) {
        tabs.push({
            text: 'Vaccine Status',
            icon: '',
            iconOnly: false,
            onClick: () => setSelectedTab('vaccine_status'),
            state: selectedTab === 'vaccine_status' ? NavItemState.Active : NavItemState.None,
        })
    }

    if (auth.roles?.pending_tests?.view || auth.roles?.pending_tests?.edit) {
        tabs.push({
            text: 'Pending Medical Tests',
            icon: '',
            iconOnly: false,
            onClick: () => setSelectedTab('pending_tests'),
            state: selectedTab === 'pending_tests' ? NavItemState.Active : NavItemState.None,
        })
    }

    return <NavBar type={NavBarTypes.Tabs} tabs={tabs} />
}

import React, { useState } from 'react'

import { SearchMaster } from './SearchMaster'
import { SearchDetail } from './SearchDetail'
import { useSearchContext } from '../../context'
// import { ShowFormButtons, ShowButtonType } from '../forms/ShowFormButtons';

import { TemplateMasterDetail } from '@mit/hui'
import { ShowFormButtons, ShowButtonType } from '../forms/ShowFormButtons'

interface Props {
    placeholder?: any
}

export const SearchMasterDetail: React.FC<Props> = () => {
    const [view, setView] = useState<number>(0)
    const context = useSearchContext()
    const { selectedItem }: any = context

    return (
        <>
            <TemplateMasterDetail
                // key={masterItems.length}
                master={<SearchMaster context={context} />}
                masterTitle={'Master'}
                detail={<SearchDetail context={context} />}
                detailTitle={selectedItem.name ?? ' '}
                navigation={<ShowFormButtons type={ShowButtonType.SEARCH} selectedItem={selectedItem} />}
                onBack={() => setView(1)}
                mobileView={view}
                startState={true}
                fullWidth={true}
                compact={true}
            />
        </>
    )
}

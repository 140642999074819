import React, { useEffect, useState } from 'react'

import { ListItemProps, ListItemState, TextboxProps, TextboxType, SearchList } from '@mit/hui'

import { PeopleSearchController } from '../../api/controller'

let controller = new PeopleSearchController()

interface Props {
    context: any
}

export const SearchMaster: React.FC<Props> = ({ context }) => {
    const { selectedItem, setSelectedItem }: any = context
    const [listItems, setListItems] = useState<any[]>([])
    const [filteredListItems, setFilteredListItems] = useState<ListItemProps[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [keyword, setKeyword] = useState<string>('')

    const searchOptions: TextboxProps = {
        name: 'Name',
        placeholderText: 'Search for person or company',
        startIcon: 'search',
        type: TextboxType.IconStart,
    }

    const filterItems: ListItemProps[] = listItems.map((item: any, index: number) => {
        console.log('item', item)
        let mapped = {
            id: item.id,
            ker: item.krb_name,
            text: item.name,
            itemIndex: index,
            state: selectedItem.id === item.id ? ListItemState.Active : ListItemState.None,
            onClick: () => setSelectedItem(item),
        }
        console.log("Mapped data", mapped, listItems);
        return mapped;
    })

    const setFilteredItems = (items) => {
        console.log("setFilteredItems", items);
        let mapped: ListItemProps[] = [...items].map((item: any, index: number) => ({
                id: item.id,
                ker: item.krb_name,
                text: item.name,
                itemIndex: index,
                state: selectedItem.id === item.id ? ListItemState.Active : ListItemState.None,
                onClick: () => setSelectedItem(item),
            })
        )
        
        console.log("Mapped data", mapped, listItems);

        setFilteredListItems(mapped);
    } 

    const onSearch = (keyword: string) => {
        setKeyword(keyword)
        /* if (keyword) {
            setIsLoading(true);
            controller.getPeople(keyword)
                .then(data => {
                    data = data.map((item: any) => ({ id: item.mit_id, name: item.display_name, krb_name: item.krb_name }));
                    setListItems(data);
                    setIsLoading(false);
                })
                .catch(err => {
                    setListItems([]);
                    // setIsLoading(false);
                })
        } */
        // console.log('keyword', keyword)
    }

    useEffect(() => {
        const timeout = setTimeout(()=>{
            if (keyword) {
                setIsLoading(true)
                controller
                    .getSearch(keyword)
                    .then((data) => {
                        // data = data.map((item: any) => ({ id: item.mit_id, name: item.display_name, krb_name: item.krb_name }));
                        setListItems(data);
                        console.log("setFilteredItems", data)
                        setFilteredItems(data);
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        setListItems([]);
                        setIsLoading(false);
                    })
            } else {
                setListItems([])
            }
        },1500)
        
        // setIsLoading(true)
        return () => clearTimeout(timeout);
    }, [keyword])

/*     console.log('filtereditems isLoading', isLoading)
    console.log('filtereditems filterItems', filterItems) */

    return (<>
        <SearchList
            isLoading={isLoading}
            selectable={true}
            compact={true}
            searchOptions={searchOptions}
            items={filteredListItems}
            height={60}
            onSearch={onSearch}
            onChange={(item: any) => setSelectedItem(item)}
        />
        <pre>
        {JSON.stringify(filteredListItems, undefined, 4)}
        </pre>
        </>
        // <FilteredList
        //     isLoading={isLoading}
        //     selectable={true}
        //     compact={true}
        //     searchOptions={searchOptions}
        //     items={filterItems}
        //     height={60}
        //     onSearch={onSearch}
        // />
    )
}

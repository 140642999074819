
export const getNestedObjectValue = (value: any, key: string) => {
    let keys = key.split('/');
    keys.forEach((key: string) => {
        value = value[key];
    })

    return value;
}


export const getFilterItems = (items: any, key: string) => {
    let options: any = [];
    let count = 0;
    for (let item of items) {
        count++;
        let value: any = getNestedObjectValue(item, key);
        if (Array.isArray(value)) {
            for (let option of value) {
                options.push({
                    id: option.id,
                    name: option.name
                });
            }
        } else {
            options.push({
                id: count,
                name: value

            });
        }

    }
    let allOptions = options.map((option: any) => option.name);
    let unique: any = new Set(allOptions);
    options = [...unique].map((name: any) => options.find((obj: any) => obj.name === name))
    return options;
}
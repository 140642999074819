import React from 'react'
// import './App.scss';
// import Home from './pages/Home';
import { Home } from './components/container/Home'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ReleaseNote } from './components/container/ReleaseNotes'

const App: React.FC = () => {
    return (
        <Router>
            <Switch>
                <Route path='/release-notes' component={ReleaseNote} />
                <Route path='/' component={Home} />
            </Switch>
        </Router>
    )
}

export default App

import React, { useEffect, useState } from 'react'
import { SponsoredAccountController } from '../../../api/controller'
import Loading from '../../Loading'
import { useFormContext } from '../../../context'
import { Banner } from '../'

import { Drawer, Button, DrawerType, DrawerSize, ButtonState, ButtonType } from '@mit/hui'
import cloneDeep from 'clone-deep'

interface Props {}

const controller = new SponsoredAccountController()

export const ResendSponsorAccountEmail: React.FC<Props> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [banners, setBanners]: any = useState({ id: null, messages: [] })
    const { sponsoredAccountEmail, setSponsoredAccountEmail }: any = useFormContext()
    const [data, setData] = useState<any>(sponsoredAccountEmail.data)

    const sendEmail = () => {
        setIsLoading(true)
        setBanners({ id: null, messages: [] })
        controller
            .resendEmail(data.person?.id)
            .then((data) => {
                setBanners({
                    id: 1,
                    header: 'Success',
                    text: data.has_override ? 'S' : 'Registration Email Sent',
                    type: 'success',
                    messages: [],
                })
                setIsLoading(false)
            })
            .catch((error) => {
                setBanners({
                    id: 1,
                    header: 'Error',
                    text: error,
                    type: 'danger',
                    messages: [],
                })
                setIsLoading(false)
            })
    }

    const handleClose = () => {
        setBanners({ id: null, messages: [] })
        setSponsoredAccountEmail({
            show: false,
            data: {},
        })
    }

    useEffect(() => {
        let data = cloneDeep(sponsoredAccountEmail.data)
        setData(data)
    }, [sponsoredAccountEmail.data])

    return (
        <Drawer
            show={sponsoredAccountEmail.show}
            onClose={handleClose}
            type={DrawerType.Right}
            size={DrawerSize.Small}
            themedColor='medical'
            header={
                <div className='d-flex flex-column'>
                    <h3>Sponsored Account Registration</h3>
                    <h5 className='mt-2'>
                        {data.person?.first_name} {data.person?.last_name} ({data.person?.id}){' '}
                    </h5>
                </div>
            }
            footer={[]}
            contents={
                <>
                    <Loading loaded={isLoading} />
                    <Banner banners={banners} setBanners={setBanners} />

                    <h6 className='mb-3'>
                        DLC sponsored account will receive an email to register their kerberos account and request an MIT ID Card if needed.
                    </h6>

                    <>
                        <Button
                            onClick={sendEmail}
                            type={ButtonType.Primary}
                            text='Resend Email'
                            state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                        />
                    </>
                </>
            }
        />
    )
}

import { BaseController } from './BaseController';

export class UniversalHoldController extends BaseController {
    readonly hold_path = 'hold';

    async getHold(mit_id: string): Promise<any> {
        let response = await this.useFetch('GET', 'get/hold', `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}?mit_id=${mit_id}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async getHoldTypes(mit_id: string): Promise<any> {
        let response = await this.useFetch('GET', 'hold/types', `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}/types?mit_id=${mit_id}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async createHold(mit_id: string, body: any): Promise<any> {
        let response = await this.useFetch('POST', 'post/hold', `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}?mit_id=${mit_id}`, body);

        if (response.status === 201) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async updateHold(mit_id: string, body: any): Promise<any> {
        let response = await this.useFetch(
            'PUT',
            'post/hold',
            `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}?hold_guid=${body.hold_guid}&mit_id=${mit_id}`,
            body
        );

        if (response.status === 201) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

    async deleteHold(hold_guid: string, body: any): Promise<any> {
        let response = await this.useFetch(
            'PATCH',
            'patch/hold',
            `${this.apiHost}/${this.apiPathMedical}/${this.hold_path}?hold_guid=${hold_guid}`,
            body
        );

        if (response.status === 204) {
            return;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }
}

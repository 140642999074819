import React, { useEffect, useState } from 'react'
import { AdvancedTable, IAdvancedTableColumn, ButtonType, ButtonState, IAdvancedTableExportCsvColumn } from '@mit/hui'
// import { getDateTimeString, getFormattedDateString } from '../../common/Util';
import { sponsoredAccountStatus } from '../../../api/model'
import { SponsoredAccountFilter, SponsoredAccountActions } from './'
import { getFormattedDateString, getFormattedPhoneNumber } from '../../../common/Util'

const getStatusText = (id: string) => {
    let status = sponsoredAccountStatus.find((item: any) => item.id === id)
    return status ? status.name : 'Unknown'
}
interface Props {
    items: any[]
    context: any
    isLoading: boolean
}

export const SponsoredAccountTable: React.FC<Props> = ({ items, context, isLoading }) => {
    const [showFilter, setShowFilter]: any = useState<boolean>(false)
    const [filteredItems, setFilteredItems]: any = useState<any[]>(null)
    const { selectedItem }: any = context

    let columns: IAdvancedTableColumn[] = [
        {
            id: 'person',
            name: 'Name',
            formatter: (item: any) => `${item.person.first_name} ${item.person.last_name}`,
        },
        {
            id: 'person/id',
            name: 'MIT ID',
        },
        {
            id: 'status/kerberos_id',
            name: 'Kerberos',
            formatter: (item: any) => item.person.krb_name || 'Requested',
        },
        {
            id: 'sponsor/name',
            name: 'Sponsor',
        },
        {
            id: 'person/organization',
            name: 'Organization',
        },
        {
            id: 'status/kerberos_id',
            name: 'Kerberos Status',
            formatter: (item: any) => getStatusText(item.status.kerberos_id),
        },
        {
            id: 'status_comments',
            name: 'Comments',
        },
    ]

    const csvExportCol: IAdvancedTableExportCsvColumn[] = [
        {
            id: 'person/id',
            name: 'MIT ID',
            formatter: null,
        },
        {
            id: 'person/name',
            name: 'Name',
            formatter: null,
        },
        {
            id: 'person/dob',
            name: 'Date of Birth',
            formatter: (item: any) => getFormattedDateString(item.person.dob),
        },
        {
            id: 'person/organization',
            name: 'External Organization',
            formatter: null,
        },
        {
            id: 'person/email',
            name: 'Email',
            formatter: null,
        },
        {
            id: 'person/phone',
            name: 'Phone',
            formatter: (item: any) => getFormattedPhoneNumber(item.person.phone),
        },
        {
            id: 'person/address1',
            name: 'Address Line 1',
            formatter: null,
        },
        {
            id: 'person/address2',
            name: 'Address Line 2',
            formatter: null,
        },
        {
            id: 'person/city',
            name: 'City',
            formatter: null,
        },
        {
            id: 'person/state',
            name: 'State',
            formatter: null,
        },
        {
            id: 'person/postalCode',
            name: 'Postal Code',
            formatter: null,
        },
        {
            id: 'sponsor/name',
            name: 'Sponsor Name',
            formatter: null,
        },
        {
            id: 'department/name',
            name: 'Department',
            formatter: null,
        },
        {
            id: 'officeAddress',
            name: 'officeAddress',
            formatter: null,
        },
        {
            id: 'costObject',
            name: 'Cost Object',
            formatter: null,
        },
        {
            id: 'startDate',
            name: 'Start date',
            formatter: (item: any) => getFormattedDateString(item.startDate),
        },
        {
            id: 'endDate',
            name: 'End date',
            formatter: (item: any) => getFormattedDateString(item.endDate),
        },
        {
            id: 'reasonForAccess',
            name: 'Reason for Access',
            formatter: null,
        },
        {
            id: 'paidByMit',
            name: 'Paid by MIT',
            formatter: null,
        },
        {
            id: 'existingMitid',
            name: 'Exisitng MIT ID',
            formatter: null,
        },
        {
            id: 'mitidDeliveryMethod',
            name: 'MIT ID Delivery Method',
            formatter: (item: any) => (item.mitidDeliveryMethod === '0' ? "Sponsor's Office Address" : "Campus Visitor's Home Address"),
        },
    ]

    const today = new Date()

    const exportOptions = {
        button: {
            type: ButtonType.Small,
            icon: 'download',
            onClick: () => alert('Download Csv'),
            text: 'Export',
            state: ButtonState.Enabled,
        },
        columns: csvExportCol,
        items: [],
        fileName: `${selectedItem.name} ${today.toISOString()}`,
        customHeader: null,
    }

    useEffect(() => {
        setFilteredItems(null)
        //eslint-disable-next-line
    }, [selectedItem])

    return (
        <>
            <AdvancedTable
                columns={columns}
                items={filteredItems || items}
                isLoading={isLoading}
                noDataText='No Accounts'
                showNoData={true}
                actionButtons={(item: any) => <SponsoredAccountActions item={item} />}
                showFilterButton={true}
                onClickFilterButton={() => setShowFilter(true)}
                showExportButton={true}
                exportOptions={exportOptions}
                maxRows={200}
                numberRowsOnScroll={100}
            />
            <SponsoredAccountFilter
                show={showFilter}
                items={items}
                onClose={() => setShowFilter(false)}
                onFilter={(items: any) => setFilteredItems(items)}
                selectedItem={selectedItem}
            />
        </>
    )
}

import React from 'react';
import Popover from '@material-ui/core/Popover';

interface Props {
	children: React.ReactNode;
	anchorEl: any;
	setAnchorEl: any;
	className?: string;
}

const Component: React.FC<Props> = ({
	children,
	anchorEl,
	setAnchorEl,
	className
}) => {
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				className={className ? className : 'popover-filter'}>
				<div
					className={
						className ? `${className}-container` : 'popover-filter-container'
					}>
					{children}
				</div>
			</Popover>
		</div>
	);
};

export default Component;

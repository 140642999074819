import React from 'react'
import { Checkbox } from '../../input';

interface Props {
    auth: any
    data: any,
    formType: string
    onChange: any
}

export const AllocationFlags: React.FC<Props> = ({ auth, data, formType, onChange }) => {


    console.log('essential', auth.roles.essential)
    return (
        <div className='flags-container'>
            {(auth.roles.essential.view || auth.roles.essential.edit) &&
                <div className='mr-5'>
                    <span>Essential</span>
                    <Checkbox
                        checked={data.is_essential}
                        onChange={(checked: boolean) => onChange(checked, 'is_essential')}
                        disabled={formType === 'VIEW' || formType === 'DELETE' || !auth.roles.essential.edit}
                    />
                </div>
            }
            <div className='mr-5'>
                <span>Designated personnel</span>
                <Checkbox
                    checked={data.ramp_down_access}
                    onChange={(checked: boolean) => onChange(checked, 'ramp_down_access')}
                    disabled={formType === 'DELETE'}
                />
            </div>
            {/* <div className='mr-5'>
                <span>Assigned Hours</span>
                <Checkbox
                    checked={data.is_voluntary}
                    onChange={(checked: boolean) => onChange(checked, 'is_voluntary')}
                    disabled={formType === 'VIEW' || formType === 'DELETE'}
                />
            </div> */}
            {/* <div>
                <span>On Campus</span>
                <Checkbox
                    checked={data.is_campus_access_required}
                    onChange={(checked: boolean) => onChange(checked, 'is_campus_access_required')}
                    disabled={formType === 'VIEW' || formType === 'DELETE'}
                />
            </div> */}
        </div>
    );
}
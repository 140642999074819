import React, { useEffect } from 'react'

import { AllocationTable } from '../table'
import { AllocationController } from '../../api/controller'

import { useParams } from 'react-router-dom'

const allocationController = new AllocationController()

interface Props {
    context: any
}

export const AllocationDetail: React.FC<Props> = ({ context }) => {
    const { items, setItems, selectedItem, isLoading, setIsLoading, refreshAllocation }: any = context
    const { assignment_type_id }: any = useParams()

    useEffect(() => {
        if (selectedItem.hasOwnProperty('id')) {
            let department_id, piId, building_key, course_code
            if (selectedItem.department_id) {
                department_id = selectedItem.department_id
                piId = selectedItem.id
            } else if (parseInt(selectedItem.id)) {
                piId = selectedItem.id
            } else {
                department_id = selectedItem.id
            }
            if (assignment_type_id === '10') {
                //Resident
                department_id = null
                piId = null
                building_key = selectedItem.id
            }
            if (assignment_type_id === '13') {
                //In Person Learning
                department_id = null
                piId = null
                building_key = null
                course_code = selectedItem.id
            }

            setIsLoading((state: any) => ({ ...state, items: true }))
            allocationController
                .getAllocations(assignment_type_id, assignment_type_id, department_id, piId, null, building_key, course_code)
                .then((data) => {
                    setItems(data.allocations)
                    setIsLoading((state: any) => ({ ...state, items: false }))
                })
                .catch((error) => {
                    console.error(error)
                    setIsLoading((state: any) => ({ ...state, items: false }))
                })
        } else if (assignment_type_id === '10') {
            //student
            setIsLoading((state: any) => ({ ...state, items: true }))
            allocationController
                .getAllocations(assignment_type_id, assignment_type_id)
                .then((data) => {
                    setItems(data.allocations)
                    setIsLoading((state: any) => ({ ...state, items: false }))
                })
                .catch((error) => {
                    console.error(error)
                    setIsLoading((state: any) => ({ ...state, items: false }))
                })
        } else {
            setIsLoading((state: any) => ({ ...state, items: false }))
        }

        //eslint-disable-next-line
    }, [selectedItem, refreshAllocation])

    if (!items.length && !isLoading.items) {
        return <h2 className='text-center'>No Allocations</h2>
    }

    return <AllocationTable items={items} context={context} isLoading={isLoading.items} />
}

import React from 'react'

import { useAppContext } from '../../context/AppContext'

import { Profile, DropdownItemProps, DropDownState } from '@mit/hui'
import { AuthProvider, Region } from '@mit/aws-react'
import appConfig from '../../app-config'
import { useEffect } from 'react'
import { BaseController } from '../../api/controller'

interface Props {
    placeholder?: any
}

export const LoggedInUser: React.FC<Props> = () => {
    const { profile, profilePicture, initializeProfile, initializeProfilePicture }: any = useAppContext()

    useEffect(() => {
        const baseController = new BaseController()

        baseController.getProfile().then((data) => {
            initializeProfile(data)
        })
        baseController.getProfilePicture().then((data) => {
            initializeProfilePicture(data)
        })
        // eslint-disable-next-line
    }, [])

    const subMenuData: DropdownItemProps[] = [
        {
            icon: 'sign-out',
            text: 'Logout',
            state: DropDownState.None,
            onClick: async () => {
                const region = new Region(appConfig)
                await new AuthProvider(region.getActiveRegionConfig()).signOut()
            },
        },
    ]

    return <Profile name={profile.display_name} subtitle={profile.department} imageUrl={profilePicture} submenu={subMenuData} />
}

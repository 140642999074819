import { BaseController } from './BaseController'

export class EmergencyOverrideController extends BaseController {
  waiv_until_path = 'pass/waive_until'

  async getOverride(mit_id: string): Promise<any> {
    let response = await this.useFetch('GET', 'get/waive_until', `${this.apiHost}/${this.apiPathPass}/${this.waiv_until_path}?mit_id=${mit_id}`)

    if (response.status === 200) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async createOverride(body: any): Promise<any> {
    let response = await this.useFetch(
      'POST',
      'post/waive_until',
      `${this.apiHost}/${this.apiPathPass}/${this.waiv_until_path}?mit_id=${body.mit_id}`,
      body
    )

    if (response.status === 200) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async deleteOverride(mit_id: string): Promise<any> {
    let response = await this.useFetch('DELETE', 'post/waive_until', `${this.apiHost}/${this.apiPathPass}/${this.waiv_until_path}?mit_id=${mit_id}`)

    if (response.status === 200) {
      let data = response.json()
      return data
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }
}

import React, { useState } from 'react'

import { SponsoredAccountMaster } from './SponsoredAccountMaster'
import { SponsoredAccountDetail } from './SponsoredAccountDetail'
import { useSponsoredAccountContext } from '../../context'
import { ShowFormButtons, ShowButtonType } from '../forms/ShowFormButtons'

import { TemplateMasterDetail } from '@mit/hui'

interface Props {
    placeholder?: any
}

export const SponsoredAccountMasterDetail: React.FC<Props> = () => {
    const [view, setView] = useState<number>(0)
    const context = useSponsoredAccountContext()
    const { selectedItem }: any = context

    return (
        <TemplateMasterDetail
            // key={masterItems.length}
            master={<SponsoredAccountMaster context={context} />}
            masterTitle={'Master'}
            detail={<SponsoredAccountDetail context={context} />}
            detailTitle={selectedItem.name ?? ' '}
            navigation={<ShowFormButtons type={ShowButtonType.SPONSORED_ACCOUNT} />}
            onBack={() => setView(1)}
            mobileView={view}
            startState={true}
            fullWidth={true}
            compact={true}
        />
    )
}

import React, { useState } from 'react'
// import { useParams } from 'react-router-dom'

import { AllocationMaster } from './AllocationMaster'
import { AllocationDetail } from './AllocationDetail'
import { useAllocationContext } from '../../context'
import { ShowFormButtons, ShowButtonType } from '../forms/ShowFormButtons'

import { TemplateMasterDetail } from '@mit/hui'

interface Props {
    placeholder?: any
}

export const AllocationMasterDetail: React.FC<Props> = () => {
    const [view, setView] = useState<number>(1)
    // const { assignment_type_id }: any = useParams();
    // const context = useTypesContext(assignment_type_id);
    const context = useAllocationContext()
    const { selectedItem }: any = context

    return (
        <>
            <TemplateMasterDetail
                // key={masterItems.length}
                master={<AllocationMaster context={context} />}
                masterTitle={'Master'}
                detail={<AllocationDetail context={context} />}
                detailTitle={selectedItem.name ?? ' '}
                navigation={<ShowFormButtons type={ShowButtonType.ALLOCATION} />}
                onBack={() => setView(1)}
                mobileView={view}
                startState={true}
                fullWidth={true}
                compact={true}
            />
        </>
    )
}

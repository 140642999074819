import React from 'react'

interface RadioGroupOptions {
    id: string
    name: string
}


interface Props {
    id: string
    value: string | boolean
    options: RadioGroupOptions[]
    onChange: (value: any) => void
    disabled?: boolean

}

export const RadioGroup: React.FC<Props> = ({ id, value, options, onChange, disabled }) => {


    const handleChange = (event: any) => {
        let value = event.target.value;
        onChange && onChange(value);
    }

    return (
        <div className='vcs-radio-container'>
            {options.map((option: RadioGroupOptions, index: number) => (
                <div>
                    <input
                        type="radio"
                        id={`${index}-${id}`}
                        name={id} value={option.id}
                        checked={option.id === value}
                        onClick={handleChange}
                        disabled={disabled}
                    />
                    <label
                        htmlFor={`${index}-${id}`}>{option.name}</label>
                </div>
            ))}
        </div>
    );
}
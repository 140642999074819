export const newAllocationData = {
    id: "1",
    person: null,
    department: null,
    principal_investigator: null,
    status: "P",
    is_resident: false,
    is_essential: false,
    is_voluntary: true,
    is_campus_access_required: true,
    preferred_hours: null,
    all_buildings: false,
    buildings: [],
    cores: [],
    schedule: [
        { dayOfWeekName: 'Sunday', day_of_week: 7, start_time: '', end_time: '', varies: false },
        { dayOfWeekName: 'Monday', day_of_week: 1, start_time: '', end_time: '', varies: false },
        { dayOfWeekName: 'Tuesday', day_of_week: 2, start_time: '', end_time: '', varies: false },
        { dayOfWeekName: 'Wednesday', day_of_week: 3, start_time: '', end_time: '', varies: false },
        { dayOfWeekName: 'Thursday', day_of_week: 4, start_time: '', end_time: '', varies: false },
        { dayOfWeekName: 'Friday', day_of_week: 5, start_time: '', end_time: '', varies: false },
        { dayOfWeekName: 'Saturday', day_of_week: 6, start_time: '', end_time: '', varies: false }
    ],
    transport_method: null,
    comments: null
};

export const initialSchedule = [
    { dayOfWeekName: 'Sunday', day_of_week: 7, start_time: '', end_time: '', varies: false },
    { dayOfWeekName: 'Monday', day_of_week: 1, start_time: '', end_time: '', varies: false },
    { dayOfWeekName: 'Tuesday', day_of_week: 2, start_time: '', end_time: '', varies: false },
    { dayOfWeekName: 'Wednesday', day_of_week: 3, start_time: '', end_time: '', varies: false },
    { dayOfWeekName: 'Thursday', day_of_week: 4, start_time: '', end_time: '', varies: false },
    { dayOfWeekName: 'Friday', day_of_week: 5, start_time: '', end_time: '', varies: false },
    { dayOfWeekName: 'Saturday', day_of_week: 6, start_time: '', end_time: '', varies: false }];

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Initialization, Logout } from '@mit/aws-react'
import './scss/main.scss'
import App from './App'
import { AppProvider } from './context/AppContext'
import { useAppInitializer } from './hooks/Initializer'
import appConfig from './app-config'

const WrappedApp: React.FC = () => {
    const { initializeApp } = useAppInitializer()

    return (
        <>
            <Router>
                <React.StrictMode>
                    <Switch>
                        <Route exact path='/logout' component={Logout} />
                        <Route path='/'>
                            <Initialization
                                App={App}
                                appInitializer={initializeApp}
                                appConfig={appConfig}
                                useCognitoAuthentication
                                loaderProps={{
                                    contactEmail: '',
                                    name: 'Covid Access',
                                    exit: false,
                                    theme: 'medical',
                                }}
                            />
                        </Route>
                    </Switch>
                </React.StrictMode>
            </Router>
        </>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <AppProvider>
            <WrappedApp />
        </AppProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

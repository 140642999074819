import React, { useState } from 'react'
import { SponsoredAccountController } from '../../../api/controller'
import { Banner } from '../Banner'
import { Dropdown } from '../../input'
import { FormRow } from '../'
import { useLookupsContext, useFormContext } from '../../../context'
import { Drawer, DrawerType, FileUpload, DrawerSize, Button, ButtonType, ButtonState } from '@mit/hui'
import { Row, Col, Alert } from 'react-bootstrap'

interface Props {
    placeholder?: any
}

const controller = new SponsoredAccountController()

export const SponsoredAccountUploadForm: React.FC<Props> = ({ placeholder }) => {
    const [department, setDepartment]: any = useState(null)
    const [errors, seterrors]: any = useState({})
    const [isLoading, setIsLoading]: any = useState<boolean>(false)
    const [banners, setBanners]: any = useState({ id: null, messages: [] })
    const [uploadFile, setUploadFile]: any = useState({ name: '', file: null })
    // const fileUploadedInput: any = useRef(null);

    const { departments }: any = useLookupsContext()
    const { sponsoredAccountUploadForm, setSponsoredAccountUploadForm }: any = useFormContext()

    const handleAllocationTypeChange = (value: any) => {
        setDepartment(value)
        seterrors((state: any) => ({ ...state, department: '' }))
    }

    const handleFileUpload = (file: any) => {
        if (file) {
            setUploadFile((state: any) => ({ ...state, name: file.name, file: file }))
            seterrors((state: any) => ({ ...state, file: '' }))
        }
    }

    const onUploadFile = () => {
        setBanners({ id: null })
        setIsLoading(true)
        controller
            .getS3Url(uploadFile.name, department.id)
            .then((data) => {
                return data.url
            })
            .then((url) => {
                controller
                    .uploadAccounts(url, uploadFile.file, uploadFile.name)
                    .then((data) => {
                        setIsLoading(false)
                        setUploadFile({ name: '', file: null })
                        setBanners({
                            id: 1,
                            header: 'Success',
                            text: 'File is being processed. You will receive an email when the upload has been completed.',
                            type: 'success',
                            messages: [],
                        })
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        setBanners({ id: 1, header: 'Error', text: 'There was a probem uploading the file', type: 'danger', messages: [] })
                    })
            })
            .catch((error) => {
                setIsLoading(false)
                setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
            })
    }

    const validateUpload = () => {
        let errors: any = {}

        if (!uploadFile.name) {
            errors.file = 'Select a .xlsx file'
        }
        if (!department) {
            errors.department = true
        }

        let hasErrors = Object.keys(errors).length > 0
        seterrors(errors)
        if (!hasErrors) {
            onUploadFile()
        }
    }

    const clearForm = () => {
        setDepartment(null)
        seterrors({})
        setBanners({ id: null })
        setUploadFile({ name: '', file: null })
        // if (fileUploadedInput.current) {
        //     fileUploadedInput.current.value = '';
        // }

        setSponsoredAccountUploadForm({
            show: false,
            data: {},
        })
    }

    return (
        <Drawer
            type={DrawerType.Right}
            size={DrawerSize.Small}
            themedColor='medical'
            show={sponsoredAccountUploadForm.show}
            header={<h3>Upload Sponsored Accounts</h3>}
            footer={[]}
            onClose={clearForm}
            contents={
                <div className='allocation-upload'>
                    <Banner banners={banners} setBanners={setBanners} />

                    <Row>
                        <Col>
                            <div className='upload-type-container'>
                                <FormRow
                                    inputComponent={<Dropdown options={departments} onChange={handleAllocationTypeChange} value={department} />}
                                    label='Department'
                                    labelPlacement='top'
                                    type='EDIT'
                                    error={errors.department}
                                    value={department}
                                    fullWidth
                                    required
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row xs='1' lg='1' className='mt-3'>
                        <Col>
                            <div className='file-upload-container'>
                                <div className='file-upload-template py-3'>
                                    <span>
                                        <a aria-hidden='true' href='/DLCSponsorTemplate.xlsx' download>
                                            Download
                                        </a>{' '}
                                        the template file.
                                    </span>
                                </div>

                                <FileUpload acceptedMimeTypes={['.xlsx']} onConfirm={handleFileUpload} isLoading={isLoading} />

                                <h5 className='mt-2'>{uploadFile.name}</h5>
                                {errors.file && <Alert variant={'danger'}>{errors.file}</Alert>}
                                <Button
                                    text='Upload Sponsored Accounts File'
                                    onClick={validateUpload}
                                    state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                    type={ButtonType.Primary}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        />
    )
}

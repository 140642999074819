import { BaseController } from './BaseController'

export class SponsoredAccountController extends BaseController {
    mapSponsoredAccounts(data: any) {
        for (let row of data) {
            row.items.map((item: any) => {
                item.person.name = `${item.person.first_name} ${item.person.last_name}`
                return item
            })
        }

        return data
    }

    async getAccounts(): Promise<any> {
        let response = await this.useFetch('GET', 'vcs', `${this.apiHost}/${this.apiPath}/vcs`)

        if (response.status === 200) {
            let data = await response.json()
            data = this.mapSponsoredAccounts(data)
            return data
        } else {
            let error = await response.json()
            return Promise.reject(error.error)
        }
    }

    async getAccountsPaginated(from: number | undefined = 1, to: number | undefined = undefined): Promise<any> {
      let path = `${this.apiHost}/${this.apiPath}/vcs`
      if (from !== undefined && to !== undefined && from > 0) {
        path += `?from=${from}&to=${to}`;
      }

        let response = await this.useFetch('GET', 'vcs', path)

        if (response.status === 200) {
            let data = await response.json()
            data = this.mapSponsoredAccounts(data)
            return data
        } else {
            let error = await response.json()
            return Promise.reject(error.error)
        }
    }

    async createAccount(body: any): Promise<any> {
        let response = await this.useFetch('POST', 'vcs', `${this.apiHost}/${this.apiPath}/vcs`, body)

        if (response.status === 201) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async updateAccount(id: string, body: any): Promise<any> {
        let response = await this.useFetch('PUT', 'vcs', `${this.apiHost}/${this.apiPath}/vcs/${id}`, body)

        if (response.status === 201) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async deleteAccount(id: string): Promise<any> {
        let response = await this.useFetch('DELETE', 'vcs', `${this.apiHost}/${this.apiPath}/vcs/${id}`)

        if (response.status === 201) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async uploadPhoto(id: string, file: any, fileName: any): Promise<any> {
        let response = await this.useFetch(
            'POST',
            'vcs/upload',
            `${this.apiHost}/${this.apiPath}/vcs/${id}/picture?file_name=${fileName}`,
            file,
            { 'Content-Type': 'binary/octet-stream', 'Content-Filename': fileName },
            true
        )

        if (response.status === 201) {
            let data = response.json()
            return data
        } else {
            let error = await response.json()
            return Promise.reject(error.message)
        }
    }

    async getS3Url(fileName: any, dlc_key: any): Promise<any> {
        let response = await this.useFetch(
            'GET',
            'get/vcs_upload_url',
            `${this.apiHost}/${this.apiPath}/vcs/upload?file_name=${fileName}&dlc_key=${encodeURIComponent(dlc_key)}`
        )

        if (response.status === 200) {
            let data = response.json()
            return data
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async uploadAccounts(url: string, file: any, fileName: any): Promise<any> {
        let response = await this.useFetch(
            'PUT',
            'vcs/upload',
            url,
            file,
            { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'Content-Filename': fileName },
            true
        )

        if (response.status === 200) {
            return true
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }

    async resendEmail(id: string): Promise<any> {
        let response = await this.useFetch('POST', 'vcs/email', `${this.apiHost}/${this.apiPath}/vcs/${id}/resend_registration_email`, {})

        if (response.status === 201) {
            return true
        } else {
            let text = await response.text()
            return Promise.reject(text)
        }
    }
}

import React, { useEffect, useState } from 'react'
import { AdvancedTable, IAdvancedTableColumn, ButtonType, IAdvancedTableExportCsvColumn, ButtonState } from '@mit/hui'
import { getDateTimeString, getFormattedDateString, get12HourTimeString } from '../../../common/Util'
import { useAppContext } from '../../../context'
import { AllocationFilter, AllocationActions, AllocationStatusFlags } from './'
import { AllocationTableToggle } from './AllocationTableToggle'
// import { IconActions } from '../../IconActions';

interface Props {
    items: any[]
    context: any
    isLoading: boolean
}

export const AllocationTable: React.FC<Props> = ({ items, context, isLoading }) => {
    // const { setAllocationForm }: any = useFormContext();
    const [showFilter, setShowFilter]: any = useState<boolean>(false)
    const [filteredItems, setFilteredItems]: any = useState<any[]>(null)
    const [complianceItems, setComplianceItems]: any = useState<any[]>(null)
    const { auth }: any = useAppContext()
    const { selectedItem, tableType }: any = context

    const canViewEssential = auth.roles.essential.view || auth.roles.essential.edit

    // const handleActionButton = (item: any, type: string) => {
    //     setAllocationForm({
    //         show: true,
    //         type: type,
    //         data: item
    //     })
    // }

    const getStatusClass = (status: string) => {
        if (status === 'not-required') return 'dim'

        if (status === 'complete') {
            return 'fa-check icon icon-completed'
        }

        return 'fa-exclamation-triangle icon icon-not-completed'
    }

    let defaultColumns: IAdvancedTableColumn[] = [
        {
            id: 'person',
            name: 'Person',
            formatter: (item: any) => (
                <div className='d-flex flex-column'>
                    <span className='d-flex'>
                        <b>{`${item.person.first_name} ${item.person.last_name}`}</b>
                        <AllocationStatusFlags item={item} />
                    </span>
                    <span>{item.person.title}</span>
                </div>
            ),
        },
        {
            id: 'department/name',
            name: 'Department',
        },
        {
            id: 'buildings',
            name: 'Buildings',
            formatter: (item: any) => {
                if (item.all_buildings) return 'All Buildings'
                return item.buildings.length ? item.buildings.map((building: any) => building.name).join(', ') : 'None'
            },
        },
        {
            id: 'cores',
            name: 'Core Facilities',
            formatter: (item: any) => (item.cores.length ? item.cores.map((core: any) => core.name).join(', ') : 'None'),
            // options: {
            //     demandPopin: true,
            //     minWidth: 2000,
            //     popinText: 'Core Facilities'
            // },
        },
        {
            id: 'preferred_hours',
            name: 'Weekly Hours',
            // formatter: (item: any) => item.preferred_hours ? item.preferred_hours : ''
        },
    ]

    if (window.location.pathname.indexOf('/search') >= 0) {
        defaultColumns.push({
            id: 'allocation_type/name',
            name: 'Allocation Type',
        })
    }

    let complianceColumns: IAdvancedTableColumn[] = [
        {
            id: 'person',
            name: 'Person',
            formatter: (item: any) => (
                <div className='d-flex flex-column'>
                    <span className='d-flex'>
                        <b>{`${item.person.first_name} ${item.person.last_name}`}</b>
                        <AllocationStatusFlags item={item} />
                    </span>
                    <span>{item.person.title}</span>
                </div>
            ),
        },
        {
            id: 'waiver',
            name: 'Waiver',
            formatter: (item: any) => (
                <div className='icon-cell'>
                    {' '}
                    {item.requirement_status['waiver'] === 'not-required' ? (
                        <span className='dim'> - </span>
                    ) : (
                        <i className={`fas ${getStatusClass(item.requirement_status['waiver'])}`}></i>
                    )}
                </div>
            ),
        },
        {
            id: 'training',
            name: 'Training',
            formatter: (item: any) => (
                <div className='icon-cell'>
                    {item.requirement_status['training'] === 'not-required' ? (
                        <span className='dim'> - </span>
                    ) : (
                        <i className={`fas  ${getStatusClass(item.requirement_status['training'])}`}></i>
                    )}
                </div>
            ),
        },
        {
            id: 'medical',
            name: 'Medical',
            formatter: (item: any) => (
                <div className='icon-cell'>
                    {item.requirement_status['medical'] === 'not-required' ? (
                        <span className='dim'> - </span>
                    ) : (
                        <i className={`fas  ${getStatusClass(item.requirement_status['medical'])}`}></i>
                    )}
                </div>
            ),
        },
        {
            id: 'attestation',
            name: 'Attestation',
            formatter: (item: any) => (
                <div className='icon-cell'>
                    {item.requirement_status['attestation'] === 'not-required' ? (
                        <span className='dim'> - </span>
                    ) : (
                        <i className={`fas  ${getStatusClass(item.requirement_status['attestation'])}`}></i>
                    )}
                </div>
            ),
        },
        {
            id: 'attestationDate',
            name: 'Attestation Date',
            formatter: (item: any) => getDateTimeString(item.attestion_submitted_on),
        },
        {
            id: 'last_medical_test_date',
            name: 'Last Test Date',
            formatter: (item: any) => getFormattedDateString(item.last_medical_test_date),
        },
        {
            id: 'next_medical_date',
            name: 'Next Test Date',
            formatter: (item: any) => getFormattedDateString(item.next_medical_date),
        },
    ]

    if (canViewEssential) {
        defaultColumns.push({
            id: 'essential',
            name: 'Essential',
            formatter: (item: any) => (item.is_essential ? 'True' : 'False'),
            options: {
                demandPopin: true,
                minWidth: 2000,
                popinText: 'Essential',
            },
        })
    }

    // const actionButtons: ButtonProps[] = [
    //     {
    //         icon: 'info-circle',
    //         type: ButtonType.Ghost,
    //         onClick: (item: any) => handleActionButton(item, 'VIEW'),
    //         text: '',
    //         state: 0,
    //         padded: false
    //     },
    //     {
    //         icon: 'edit',
    //         type: ButtonType.Ghost,
    //         onClick: (item: any) => handleActionButton(item, 'EDIT'),
    //         text: '',
    //         state: 0,
    //         padded: false
    //     },
    //     {
    //         icon: 'times-circle',
    //         type: ButtonType.Ghost,
    //         onClick: (item: any) => handleActionButton(item, 'DELETE'),
    //         text: '',
    //         state: 0,
    //         padded: false
    //     }
    // ]

    const csvExportCol: IAdvancedTableExportCsvColumn[] = [
        {
            id: 'person/krb_name',
            name: 'Kerberos',
            formatter: null,
        },
        {
            id: 'person/name',
            name: 'Name',
            formatter: null,
        },
        {
            id: 'person/title',
            name: 'Title',
            formatter: null,
        },
        {
            id: 'department/name',
            name: 'Department',
            formatter: (item: any) => item.department.name.split(',').join(' '),
        },
        {
            id: 'principal_investigator/krb_name',
            name: 'PI Kerberos',
            formatter: (item: any) => (item.principal_investigator ? item.principal_investigator.krb_name : ''),
        },
        {
            id: 'principal_investigator/name',
            name: 'PI Name',
            formatter: (item: any) =>
                item.principal_investigator ? `${item.principal_investigator.first_name} ${item.principal_investigator.last_name}` : '',
        },
        {
            id: 'allocation_type/name',
            name: 'Allocation Type',
            formatter: null,
        },
        {
            id: 'transport_method',
            name: 'Transport Method',
            formatter: (item: any) => (item.transport_method ? item.transport_method.name : ''),
        },
        {
            id: 'is_campus_access_required',
            name: 'Campus Access Required',
            formatter: null,
        },
        {
            id: 'is_voluntary',
            name: 'Voluntary',
            formatter: null,
        },
        {
            id: 'is_essential',
            name: 'Essential',
            formatter: null,
        },
        {
            id: 'commments',
            name: 'Comments',
            formatter: (item: any) => (item.comments ? item.comments.replaceAll(',', '') : ''),
        },
        {
            id: 'buildings',
            name: 'Buildings',
            formatter: (item) => item.buildings.map((item: any) => item.id).join('; '),
        },
        {
            id: 'cores',
            name: 'Cores',
            formatter: (item) => item.cores.map((item: any) => item.id).join('; '),
        },
        {
            id: 'is_requirements_complete',
            name: 'Requirements Complete',
            formatter: (item) => item.cores.map((item: any) => item.id).join('; '),
        },
        {
            id: 'is_daily_attestation_complete',
            name: 'Attestation Complete',
            formatter: (item) => item.cores.map((item: any) => item.id).join('; '),
        },
        {
            id: 'attestion_submitted_on',
            name: 'Attestation Submitted On',
            formatter: (item) => getDateTimeString(item.attestion_submitted_on),
        },
        {
            id: 'last_medical_test_date',
            name: 'Last Medical Test',
            formatter: (item) => getFormattedDateString(item.last_medical_test_date),
        },
        {
            id: 'next_medical_date',
            name: 'Next Medical Test',
            formatter: (item) => getFormattedDateString(item.next_medical_date),
        },
        {
            id: 'last_swipe_status',
            name: 'Last Swipe Status',
            formatter: null,
        },
        {
            id: 'schedule',
            name: '',
            formatter: (x: any) => {
                let row = []
                for (let i = 1; i <= 7; i++) {
                    let day = x.schedule.filter((item: any) => item.day_of_week === i)
                    if (day.length) {
                        row.push(get12HourTimeString(day[0].start_time))
                        row.push(get12HourTimeString(day[0].end_time))
                    } else {
                        row.push('')
                        row.push('')
                    }
                }
                return row.join(',')
            },
        },
        {
            id: 'created_on',
            name: 'Created On',
            formatter: (item: any) => getDateTimeString(item.created_on, true),
        },
        {
            id: 'created_by',
            name: 'Created By',
            formatter: null,
        },
        {
            id: 'updated_on',
            name: 'Updated On',
            formatter: (item: any) => getDateTimeString(item.updated_on, true),
        },
        {
            id: 'updated_by',
            name: 'Updated By',
            formatter: null,
        },
    ]

    const today = new Date()

    const exportOptions = {
        button: {
            type: ButtonType.Small,
            icon: 'download',
            onClick: () => alert('Download Csv'),
            text: 'Export',
            state: ButtonState.Enabled,
        },
        columns: csvExportCol,
        items: [],
        fileName: `${selectedItem.name} ${today.toISOString()}`,
        customHeader:
            ',,,,,,,,,,,,,,,,,,,,,,SCHEDULE,,,,,,,,,,,,,\n,,,,,,,,,,,,,,,,,,,,,,Monday,,Tuesday,,Wednesday,,Thursday,,Friday,,Saturday,,Sunday,\n',
    }

    useEffect(() => {
        setFilteredItems(null)
        //eslint-disable-next-line
    }, [selectedItem])

    return (
        <>
            <AllocationTableToggle
                context={context}
                filteredItems={filteredItems || items}
                onFilter={(items: any) => setComplianceItems(items)}
                isLoading={isLoading}
            />
            <AdvancedTable
                columns={tableType === 'default' ? defaultColumns : complianceColumns}
                items={complianceItems || filteredItems || items}
                isLoading={isLoading}
                noDataText='No Allocations'
                showNoData={true}
                actionButtons={(item: any) => <AllocationActions item={item} />}
                showFilterButton={true}
                onClickFilterButton={() => setShowFilter(true)}
                showExportButton={true}
                exportOptions={exportOptions}
                maxRows={200}
                numberRowsOnScroll={100}
            />
            <AllocationFilter
                show={showFilter}
                items={items}
                onClose={() => setShowFilter(false)}
                onFilter={(items: any) => setFilteredItems(items)}
                canViewEssential={canViewEssential}
                selectedItem={selectedItem}
            />
        </>
    )
}

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { AccordionItem, FilteredList, ListItemProps, ListItemState, TextboxProps, TextboxType, AccordionItemState } from '@mit/hui'

import { ListItemsController } from '../../api/controller'
import { ListItem } from '../../types/Lookup'

let controller = new ListItemsController()

interface Props {
    context: any
}

export const AllocationMaster: React.FC<Props> = ({ context }) => {
    const { setFirstLoad, listItems, isLoading, selectedItem, setSelectedItem, setListItems, setIsLoading }: any = context
    const { assignment_type_id }: any = useParams()

    // console.log(selectedItem);

    const getAccordian = (props: ListItemProps, item: any) => {
        let filter = props.filter ? props.filter.toLowerCase() : ''
        let headerFiltered = item.name.toLowerCase().indexOf(filter) !== -1
        let subItems = headerFiltered
            ? item.items
            : item.items
            ? item.items.filter((item: any) => item.name.toLowerCase().indexOf(filter) !== -1)
            : []
        let hasSubItems = subItems.length

        if (item.id.toLowerCase().indexOf(filter) !== -1 || item.name.toLowerCase().indexOf(filter) !== -1 || hasSubItems) {
            return (
                <>
                    <AccordionItem
                        key={item.id}
                        // icon='building'
                        title={item.name}
                        onClick={() => setSelectedItem(item)}
                        // state={AccordionItemState.Expanded}
                        items={subItems.map((item: any) => ({
                            text: item.name,
                            state:
                                item.id === selectedItem.id && (selectedItem.department_id ? selectedItem.department_id === item.department_id : true)
                                    ? ListItemState.Active
                                    : ListItemState.None,
                            onClick: () => setSelectedItem(item),
                        }))}
                        state={
                            filter
                                ? hasSubItems
                                    ? AccordionItemState.Expanded
                                    : AccordionItemState.Contracted
                                : item.id === selectedItem.id || subItems.filter((item: any) => item.id === selectedItem.id)
                                ? AccordionItemState.Expanded
                                : AccordionItemState.Contracted
                        }
                    />
                </>
            )
        }
        return null
    }

    const searchOptions: TextboxProps = {
        name: 'Name',
        placeholderText: 'Filter List',
        startIcon: 'search',
        type: TextboxType.IconStart,
    }

    const filterItems: ListItemProps[] = listItems.map((item: any, index: number) => {
        let data = {
            id: item.id,
            text: item.name,
            state: selectedItem.id === item.id ? ListItemState.Active : ListItemState.None,
            template: item.items
                ? (props: ListItemProps) => {
                      return getAccordian(props, item)
                  }
                : null,
            onClick: () => setSelectedItem(item),
            itemIndex: index,
        }

        // console.log(data)
        return data
    })

    if (filterItems.length) {
        filterItems[0].state = ListItemState.Active
    }

    // const filterItems: ListItemProps[] = [
    //     {
    //         text: 'test 1',
    //         itemIndex: 0,
    //         state: ListItemState.Active
    //     },
    //     {
    //         text: 'test 1',
    //         itemIndex: 1,
    //         state: ListItemState.None,
    //         template: () => <h1>Test</h1>
    //     }, {
    //         text: 'test 1',
    //         itemIndex: 2,
    //         state: ListItemState.None
    //     }

    // ]

    // console.log("filterItems", filterItems);

    useEffect(() => {
        setIsLoading((state: any) => ({ ...state, list: true, items: true }))
        controller
            .getListItems(assignment_type_id)
            .then((data: ListItem[]) => {
                setListItems(data)

                if (data.length) {
                    let firstItem = data[0]
                    // if (firstItem.items && firstItem.items.length) {
                    //     setSelectedItem(firstItem.items[0])
                    // } else {
                    //     setSelectedItem(firstItem);
                    // }
                    setSelectedItem(firstItem)
                } else if (assignment_type_id === '10') {
                    setSelectedItem({ test: 1 })
                }
                setIsLoading((state: any) => ({ ...state, list: false }))
                setFirstLoad(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading((state: any) => ({ ...state, list: false }))
                // setListItems([])
            })

        //eslint-disable-next-line
    }, [assignment_type_id])

    return (
        <FilteredList
            name=''
            isLoading={isLoading.list}
            selectable={true}
            compact={true}
            searchOptions={searchOptions}
            items={filterItems}
            height={60}
        />
    )
}

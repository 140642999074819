import React, { useState } from 'react'
import { AllocationController } from '../../../api/controller'
import { Banner } from '../Banner'
import { Dropdown } from '../../input'
import { FormRow } from '../'
import { useLookupsContext, useFormContext } from '../../../context'
import { Drawer, DrawerType, FileUpload, DrawerSize, Button, ButtonType, ButtonState } from '@mit/hui'
import { Row, Col, Alert } from 'react-bootstrap'

interface Props {
    placeholder?: any
}

const controller = new AllocationController()

export const AllocationUploadForm: React.FC<Props> = ({ placeholder }) => {
    const [allocationType, setAllocationType]: any = useState(null)
    const [uploadType, setUploadType]: any = useState('ADD')
    const [errors, seterrors]: any = useState({})
    const [isLoading, setIsLoading]: any = useState<boolean>(false)
    const [banners, setBanners]: any = useState({ id: null, messages: [] })
    const [uploadFile, setUploadFile]: any = useState({ name: '', file: null })
    // const fileUploadedInput: any = useRef(null);

    const { allocationTypes }: any = useLookupsContext()
    const { allocationUploadForm, setAllocationUploadForm }: any = useFormContext()

    const handleAllocationTypeChange = (value: any) => {
        setAllocationType(value)
        seterrors((state: any) => ({ ...state, allocation_type: '' }))
    }

    const handleFileUpload = (file: any) => {
        if (file) {
            setUploadFile((state: any) => ({ ...state, name: file.name, file: file }))
            seterrors((state: any) => ({ ...state, file: '' }))
        }
    }

    const onUploadFile = () => {
        setBanners({ id: null })
        setIsLoading(true)
        controller
            .getS3Url(uploadFile.name, allocationType.id, uploadType)
            .then((data) => {
                return data.url
            })
            .then((url) => {
                controller
                    .uploadAccounts(url, uploadFile.file, uploadFile.name)
                    .then((data) => {
                        setIsLoading(false)
                        setUploadFile({ name: '', file: null })
                        setBanners({
                            id: 1,
                            header: 'Success',
                            text: 'File is being processed. You will receive an email when the upload has been completed.',
                            type: 'success',
                            messages: [],
                        })
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        setBanners({ id: 1, header: 'Error', text: 'There was a probem uploading the file', type: 'danger', messages: [] })
                    })
            })
            .catch((error) => {
                setIsLoading(false)
                setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
            })
    }

    const validateUpload = () => {
        let errors: any = {}

        if (!uploadFile.name) {
            errors.file = 'Select a .xlsx file'
        }
        if (!allocationType) {
            errors.allocation_type = true
        }

        let hasErrors = Object.keys(errors).length > 0
        seterrors(errors)
        if (!hasErrors) {
            onUploadFile()
        }
    }

    const clearForm = () => {
        setAllocationType(null)
        seterrors({})
        setBanners({ id: null })
        setUploadFile({ name: '', file: null })
        // if (fileUploadedInput.current) {
        //     fileUploadedInput.current.value = '';
        // }

        setAllocationUploadForm({
            show: false,
            data: {},
        })
    }

    return (
        <Drawer
            type={DrawerType.Right}
            size={DrawerSize.Small}
            show={allocationUploadForm.show}
            header={<h3>Upload Allocations</h3>}
            footer={[]}
            themedColor='medical'
            onClose={clearForm}
            contents={
                <div className='allocation-upload'>
                    <Banner banners={banners} setBanners={setBanners} />

                    <Row>
                        <Col>
                            <div className='upload-type-container'>
                                <FormRow
                                    inputComponent={
                                        <Dropdown options={allocationTypes} onChange={handleAllocationTypeChange} value={allocationType} />
                                    }
                                    label='Allocation Type'
                                    labelPlacement='top'
                                    type='EDIT'
                                    error={errors.allocation_type}
                                    value={allocationType}
                                    fullWidth
                                    required
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row xs='1' lg='1' className='mt-3'>
                        <Col>
                            <div className='file-upload-container'>
                                <div className='file-upload-template py-3'>
                                    <span>
                                        <a aria-hidden='true' href='/CovidAccessAllocationTemplate.xlsx' download>
                                            Download
                                        </a>{' '}
                                        the template file.
                                    </span>
                                </div>

                                <FileUpload acceptedMimeTypes={['.xlsx']} onConfirm={handleFileUpload} isLoading={isLoading} />

                                <h5 className='mt-2'>{uploadFile.name}</h5>
                                {errors.file && <Alert variant={'danger'}>{errors.file}</Alert>}
                            </div>
                        </Col>
                    </Row>
                    <Row xs='1' xl='2' className='mt-3 mb-4'>
                        {/* <Col xl='12'>
                        <div className='upload-type-container'>
                            <label>Upload Action</label>
                        </div>
                    </Col> */}

                        <Col xl='12'>
                            <div className='upload-radio-container'>
                                <label htmlFor='add'>Add</label>
                                <input
                                    type='radio'
                                    id='add'
                                    name='paid'
                                    value='YES'
                                    checked={uploadType === 'ADD'}
                                    onClick={(e: any) => setUploadType('ADD')}
                                    className='mr-3'
                                />
                                <label htmlFor='replace'>Add & Replace</label>
                                <input
                                    type='radio'
                                    id='replace'
                                    name='paid'
                                    value='NO'
                                    checked={uploadType === 'REPLACE'}
                                    onClick={(e: any) => setUploadType('REPLACE')}
                                />
                            </div>
                        </Col>
                        <Col xl='12' className='mt-2 upload-note-text'>
                            <span>
                                Note: Add & Replace will remove any allocations not on your upload for the same PI/Department/Allocation Type
                                combination
                            </span>
                        </Col>
                        <Col xl='12' className='mt-2 '>
                            <Button
                                text='Upload Allocation File'
                                onClick={validateUpload}
                                state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                type={ButtonType.Primary}
                            />
                        </Col>
                    </Row>
                </div>
            }
        />
    )
}

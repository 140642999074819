import React from 'react'
import { Col, Alert } from 'react-bootstrap'
import { Label } from '@mit/hui'
import { getDayOfWeekName } from '../../../common/Util'
import { Checkbox, TimePicker } from '../../input'
// import { FormRow } from '../';

interface Props {
    data: any
    formType: string
    errors: any
    handleTimeChange: any
    handleChange: any
}

export const AllocationSchedule: React.FC<Props> = ({ data, formType, errors, handleTimeChange, handleChange }) => {
    return (
        <>
            <Col xs='12' lg='11' xl='10'>
                <div className='schedule-container mb-2'>
                    <span className='time-label'> </span>
                    <div className='varies-label'>
                        <Label text='Varies' />
                    </div>
                    <div className='schedule-label'>
                        <Label text='Start Time' />
                    </div>
                    <div className='schedule-label'>
                        <Label text='End Time' />
                    </div>
                </div>
                {data.schedule &&
                    data.schedule.map((item: any) => (
                        <div>
                            <div key={item.day_of_week} className='schedule-container mb-2'>
                                <div className='time-label'>
                                    <Label text={getDayOfWeekName(item.day_of_week) || ''} />
                                </div>
                                <Checkbox
                                    disabled={formType === 'VIEW' || formType === 'DELETE'}
                                    // className='varies-check'
                                    checked={item.varies}
                                    onChange={(event: any) => handleTimeChange(event, item, 'varies')}
                                />
                                <div className='time-picker-container'>
                                    <TimePicker
                                        value={item.start_time}
                                        disabled={formType === 'VIEW' || formType === 'DELETE' || item.varies}
                                        handleChange={(event: any) => handleTimeChange(event, item, 'start_time')}
                                    />
                                </div>
                                <div className='time-picker-container'>
                                    <TimePicker
                                        value={item.end_time}
                                        disabled={item.data_time === null || item.start_time === '' || formType === 'VIEW' || formType === 'DELETE'}
                                        filterTime={item.start_time}
                                        handleChange={(event: any) => handleTimeChange(event, item, 'end_time')}
                                    />
                                </div>
                            </div>
                            {errors.schedule && errors.schedule[`${item.day_of_week}`] && (
                                <Alert variant={'danger'}>{errors.schedule[`${item.day_of_week}`]}</Alert>
                            )}
                        </div>
                    ))}
                {errors['noSchedule'] && <Alert variant={'danger'}>{errors['noSchedule']}</Alert>}
            </Col>
        </>
    )
}

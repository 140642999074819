import { BaseController } from "./BaseController";

export class ListItemsController extends BaseController {

    async mapDepartmentPi(data: any) {
        return data.map((item: any) => ({
            id: item.department.id,
            name: item.department.name,
            items: item.principal_investigators.map((item: any) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`,
                department_id: item.department_id
            }))
        }));
    }

    async mapBuildingList(data: any) {
        return data.map((item: any) => ({
            id: item.id,
            name: `${item.name_long} (${item.name})`
        }))
    }

    async getListItems(assignment_type_id: string): Promise<any> {

        let response = await this.useFetch('GET', `listItems/${assignment_type_id}`, `${this.apiHost}/${this.apiPath}/allocations/list_items?assignment_type_id=${assignment_type_id}`);

        if (response.status === 200) {
            let data = await response.json();

            if (data.length && data[0].principal_investigators) {
                data = this.mapDepartmentPi(data);
            }

            if (data.length && data[0].name_long) {
                data = this.mapBuildingList(data);
            }

            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

}

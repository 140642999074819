import React from 'react'

import { ActionType } from '../../../../types/Table';

import { FormRow } from '../../';
import { Input, RadioGroup, Checkbox, DatePicker } from '../../../input';
import { getFormattedDateString } from '../../../../common/Util';

interface Props {
    data: any
    formType: ActionType
    errors: any
    onChangeInput: (value: any, key: string) => void
}


export const AccessInformation: React.FC<Props> = ({ data, errors, formType, onChangeInput }) => {
    return (
        <>
            <h6>Access Information</h6>
            <FormRow
                inputComponent={
                    <DatePicker
                        placeholder="MM/DD/YYYY"
                        value={data.startDate}
                        onChange={(value: any) => onChangeInput(value, 'startDate')} />
                }
                label='Start Date'
                type={formType}
                value={getFormattedDateString(data.startDate)}
                error={errors.startDate}
                required
            />
            <FormRow
                inputComponent={
                    <DatePicker
                        placeholder="MM/DD/YYYY"
                        value={data.endDate}
                        onChange={(value: any) => onChangeInput(value, 'endDate')} />
                }
                label='End Date'
                type={formType}
                value={getFormattedDateString(data.endDate)}
                error={errors.endDate}
            />
            <FormRow
                inputComponent={
                    <Input
                        value={data.reasonForAccess}
                        onChange={(value: any) => onChangeInput(value, 'reasonForAccess')}
                        type='string' />
                }
                label='Reason For Access'
                type={formType}
                value={data.reasonForAccess}
                error={errors.reasonForAccess}
                required
            />
            <FormRow
                inputComponent={
                    <Checkbox
                        checked={data.paidByMit}
                        onChange={(value: any) => onChangeInput(value, 'paidByMit')}
                        disabled={formType === 'VIEW' || formType === 'DELETE'} />
                }
                label='Is the Campus Visitor being paid by MIT directly?'
                type={formType}
                value={data.paidByMit ? 'Yes' : 'No'}
                error={errors.paidByMit}
            />
            <FormRow
                inputComponent={
                    <Checkbox
                        checked={data.existingMitid}
                        onChange={(value: any) => onChangeInput(value, 'existingMitid')}
                        disabled={formType !== 'NEW'} />
                }
                label='Does the Campus Visitor have an existing MIT ID number?'
                type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                value={data.existingMitid ? 'Yes' : 'No'}
                error={errors.existingMitid}
            />
            {
                data.existingMitid &&
                <FormRow
                    inputComponent={
                        <Input
                            value={data.person?.id}
                            onChange={(value: any) => onChangeInput(value, 'person/id')}
                            type='number'
                            maxLength={9}
                        />
                    }
                    label='Existing MIT ID'
                    type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                    value={data.person?.id}
                    error={errors.person?.id}
                    required
                />
            }
            <FormRow
                inputComponent={
                    <Checkbox
                        checked={data.mitidCardNeeded}
                        onChange={(value: any) => onChangeInput(value, 'mitidCardNeeded')}
                        disabled={formType === 'VIEW' || formType === 'DELETE'} />
                }
                label='Is an MIT ID Card needed?'
                type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                value={data.mitidCardNeeded ? 'Yes' : 'No'}
                error={errors.mitidCardNeeded}
            />
            {data.mitidCardNeeded &&
                <FormRow
                    inputComponent={
                        <RadioGroup
                            id='delivered'
                            value={data.mitidDeliveryMethod}
                            onChange={(value: any) => onChangeInput(value, 'mitidDeliveryMethod')}
                            disabled={formType === 'VIEW' || formType === 'DELETE'}
                            options={[{
                                id: 'O',
                                name: "Sponsor's Office Address"
                            },
                            {
                                id: 'H',
                                name: "Campus Visitor's Home Address"
                            }]}

                        />
                    }
                    label='Where would you like the MIT ID card delivered?'
                    type={formType === 'NEW' ? 'NEW' : 'VIEW'}
                    value={data.mitidDeliveryMethod === 'O' ? "Sponsor's Office Address" : "Campus Visitor's Home Address"}
                    error={errors.mitidDeliveryMethod}
                />
            }
        </>
    );
}
import { BaseController } from "./BaseController";

export class CardSwipesController extends BaseController {
    waiv_until_path = 'pass/waive_until';

    async getSwipes(mit_id: string): Promise<any> {

        let response = await this.useFetch('GET', 'get/swipes', `${this.apiHost}/${this.apiPath}/access/card/swipes/${mit_id}`);

        if (response.status === 200) {
            let data = response.json();
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

}

import React, { useEffect, useState } from 'react'
import { Drawer, DrawerSize, DrawerType, Button, ButtonType } from '@mit/hui'
import { FormRow } from '../../forms'
import { Dropdown } from '../../input'
import { getFilterItems } from '../../../common'
import { sponsoredAccountStatus } from '../../../api/model'
import cloneDeep from 'clone-deep'

const initialFilters = {
    person: [],
    sponsor: [],
    organizaton: [],
    kerberos_status: [],
    id_card_status: [],
}

interface Props {
    items: any
    show: boolean
    onClose: () => void
    onFilter: (items: any) => void
    selectedItem: any
}

export const SponsoredAccountFilter: React.FC<Props> = ({ items, show, onClose, onFilter, selectedItem }) => {
    const [filters, setFilters] = useState<any>(cloneDeep(initialFilters))
    const [options, setOptions] = useState<any>({})

    const handleChange = (value: any, key: string) => {
        setFilters((prev: any) => ({ ...prev, [key]: value }))
        // console.log(options);
    }

    const handleFilter = (filterObj: any, bClose: boolean = true) => {
        let data = []
        if (!filterObj) filterObj = filters
        filterObj = { ...filterObj }
        for (let item of items) {
            let bfilterObj = {
                person:
                    filterObj.person && filterObj.person.length
                        ? filterObj.person.filter((person) => person.name === item.person.name).length > 0
                        : true,
                sponsor:
                    filterObj.sponsor && filterObj.sponsor.length
                        ? filterObj.sponsor.filter((sponsor) => sponsor.name === item.sponsor.name).length > 0
                        : true,
                organization:
                    filterObj.organization && filterObj.organization.length
                        ? filterObj.organization.filter((organization) => organization.name === item.person.organization).length > 0
                        : true,
                kerberos_status:
                    filterObj.kerberos_status && filterObj.kerberos_status.length
                        ? filterObj.kerberos_status.filter((kerberos_status) => kerberos_status.name === item.status.kerberos_id).length > 0
                        : true,
                id_card_status:
                    filterObj.id_card_status && filterObj.id_card_status.length
                        ? filterObj.id_card_status.filter((id_card_status) => id_card_status.name === item.status.id_car).length > 0
                        : true,
            }

            if (Object.keys(bfilterObj).filter((filter: string) => bfilterObj[filter]).length === Object.keys(bfilterObj).length) {
                data.push(item)
            }
        }
        // console.log(data);
        onFilter(data)
        if (bClose) onClose()
    }

    const clearFilter = () => {
        let filterObj = cloneDeep(initialFilters)
        setFilters(filterObj)
        handleFilter(filterObj, false)
    }

    useEffect(() => {
        if (show) {
            let options = {
                person: getFilterItems(items, 'person/name'),
                sponsor: getFilterItems(items, 'sponsor/name'),
                organization: getFilterItems(items, 'person/organization'),
                status: sponsoredAccountStatus,
            }
            setOptions(options)
        }
        //eslint-disable-next-line
    }, [show])

    useEffect(() => {
        clearFilter()
        //eslint-disable-next-line
    }, [selectedItem])

    return (
        <Drawer
            show={show}
            type={DrawerType.Left}
            size={DrawerSize.Small}
            themedColor='medical'
            onClose={onClose}
            header={<h4>Filter SponsoredAccounts</h4>}
            footer={[]}
            contents={
                <>
                    <FormRow
                        inputComponent={
                            <Dropdown
                                options={options.person}
                                onChange={(value: any) => handleChange(value, 'person')}
                                value={filters.person}
                                isMulti
                            />
                        }
                        label='Person'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={
                            <Dropdown
                                options={options.sponsor}
                                onChange={(value: any) => handleChange(value, 'sponsor')}
                                value={filters.sponsor}
                                isMulti
                            />
                        }
                        label='Sponsor'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={
                            <Dropdown
                                options={options.organization}
                                onChange={(value: any) => handleChange(value, 'organization')}
                                value={filters.organization}
                                isMulti
                            />
                        }
                        label='Organization'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={
                            <Dropdown
                                options={options.status}
                                onChange={(value: any) => handleChange(value, 'kerbero_status')}
                                value={filters.kerbero_status}
                                isMulti
                            />
                        }
                        label='Kerberos Status'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <FormRow
                        inputComponent={
                            <Dropdown
                                options={options.status}
                                onChange={(value: any) => handleChange(value, 'id_card_status')}
                                value={filters.id_card_status}
                                isMulti
                            />
                        }
                        label='ID Card Status'
                        labelPlacement='top'
                        type='EDIT'
                        fullWidth
                    />
                    <div className='mt-3 ml-3'>
                        <Button type={ButtonType.Primary} icon='filter' text='Filter' onClick={handleFilter} />
                        &nbsp;&nbsp;
                        <Button type={ButtonType.Ghost | ButtonType.Primary} text='Clear Filters' onClick={clearFilter} />
                    </div>
                </>
            }
        />
    )
}

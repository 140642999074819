import React, { useEffect, useState } from 'react';

import { Dropdown } from './';
// import Select from 'react-select';

interface Props {
    value: any
    handleChange: any
    disabled?: boolean
    filterTime?: any
}


export const TimePicker: React.FC<Props> = ({ value, handleChange, disabled, filterTime }) => {

    const [options, setOptions]: any = useState([]);


    const getValue = () => {
        let sTime = value;
        if (sTime) {
            if (sTime.indexOf("T") > 0) {
                sTime = sTime.split("T")[1];
            }
            var sPostFix;
            var sHours = parseInt(sTime.split(":")[0]);
            var sMinutes = sTime.split(":")[1];
            if (sHours < 13) {
                sPostFix = "am";
            } else {
                sPostFix = "pm";
                sHours = sHours - 12;
            }

            return {
                id: value,
                name: sHours + ":" + sMinutes + sPostFix
            }
        }
        return {};
    }

    useEffect(() => {
        let options = [];
        let option: any = {};
        //create options
        var hours, minutes, ampm;
        for (var i = 0; i <= 1460; i += 60) {
            hours = Math.floor(i / 60);
            minutes = i % 60;
            if (minutes < 10) {
                minutes = '0' + minutes; // adding leading zero
            }

            ampm = hours % 24 < 12 ? 'am' : 'pm';
            hours = hours % 12;
            if (hours === 0) {
                hours = 12;
            }

            let idHours = Math.floor(i / 60) + '';
            idHours = idHours.length === 2 ? idHours : `0${idHours}`

            option = { id: `${idHours}:${minutes}:00`, name: `${hours}:${minutes}${ampm}` };
            options.push(option);

            if (filterTime) {
                //remove prior times
                // debugger;
                if (option.id === filterTime) {
                    options = [];
                }
            }

            // console.log(`${hours}:${minutes}${ampm}`);
            // console.log(`${idHours}:${minutes}`);
        }
        setOptions(options);

    }, [filterTime])




    return (
        // <select placeholder='' className='time-picker' onChange={handleChange} value={value} disabled={disabled ? true : false} >
        //     <option value=''></option>
        //     {
        //         options.map((item: any) => (
        //             <option key={item.value} value={item.value}>{item.label}</option>
        //         ))
        //     }

        // </select >
        <Dropdown
            options={options}
            value={getValue()}
            placeholder=''
            onChange={handleChange}
            disabled={disabled}

        />
    );
};


import React, { useContext } from 'react'
import { Button } from '@mit/hui'
import { newAllocationData } from '../../api/model'
import { SearchNav } from '../nav'

import CloneDeep from 'clone-deep'

import { FormContext } from '../../context'
import { getEnvPath } from '../../common'

export enum ShowButtonType {
    ALLOCATION,
    SPONSORED_ACCOUNT,
    SEARCH,
}

interface Props {
    type: ShowButtonType
    selectedItem?: any
}

export const ShowFormButtons: React.FC<Props> = ({ type, selectedItem }) => {
    const {
        setAllocationForm,
        setAllocationUploadForm,
        // setSponsoredAccountForm,
        setSponsoredAccountUploadForm,
        setEmergencyOverrideForm,
        setResidentHold,
    }: any = useContext(FormContext)

    if (type === ShowButtonType.ALLOCATION)
        return (
            <div className='form-buttons-container'>
                <>
                    <Button
                        onClick={() => setAllocationForm({ show: true, type: 'NEW', data: CloneDeep(newAllocationData) })}
                        text={'New Allocation'}
                        icon={'plus'}
                    />

                    <Button onClick={() => setAllocationUploadForm({ show: true, data: {} })} text={' Upload Allocation'} icon={'cloud-upload'} />
                </>
            </div>
        )

    if (type === ShowButtonType.SPONSORED_ACCOUNT)
        return (
            <div className='form-buttons-container'>
                <>
                    <Button
                        // onClick={() => setSponsoredAccountForm({ show: true, type: 'NEW', data: CloneDeep(newSponsoredAccountData) })}
                        onClick={() => window.open(`https://sponsored-accounts${getEnvPath()}.mit.edu/`, '_blank')}
                        text={'New Sponsored Account'}
                        icon={'plus'}
                    />

                    <Button
                        onClick={() => setSponsoredAccountUploadForm({ show: true, data: {} })}
                        text={' Upload Sponsored Account'}
                        icon={'cloud-upload'}
                    />
                </>
            </div>
        )

    return (
        <>
            {selectedItem && selectedItem.auth && (
                <div className='form-buttons-container'>
                    {selectedItem.auth?.emergencyOverride && (
                        <Button
                            onClick={() => setEmergencyOverrideForm({ show: true, data: { ...selectedItem } })}
                            text={' Emergency Override'}
                            icon={'first-aid'}
                        />
                    )}
                    {selectedItem.auth?.residentHold && (
                        <Button onClick={() => setResidentHold({ show: true, data: { ...selectedItem } })} text={'Resident Hold'} icon={'house'} />
                    )}
                </div>
            )}
            <SearchNav />
        </>
    )
}

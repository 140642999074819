import React, { useEffect, useState } from 'react'
import { Drawer, DrawerType, DrawerSize, GridContainer, LayoutColumn, Button, ButtonType, ButtonState } from '@mit/hui'
import { useFormContext, useAppContext, useLookupsContext, useAllocationContext } from '../../../context'
import { SponsoredAccountController } from '../../../api/controller'
import { Banner } from '../Banner'
import CloneDeep from 'clone-deep'
import { SponsorInformation, AccessInformation, Address, PersonalInformation } from './sections'
import Loading from '../../Loading'
import { getDateTimeString } from '../../../common'

const controller = new SponsoredAccountController()

interface Props {
    placeholder?: any
}

export const SponsoredAccountForm: React.FC<Props> = ({ placeholder }) => {
    //contexts
    const { sponsoredAccountForm, setSponsoredAccountForm }: any = useFormContext()
    const { auth }: any = useAppContext()
    const { setRefreshAllocation }: any = useAllocationContext()
    //local state
    const [data, setData] = useState<any>(sponsoredAccountForm.data)
    const [errors, setErrors] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [banners, setBanners]: any = useState({ id: null, messages: [] })

    const formType = sponsoredAccountForm.type

    const getFormTypeText = () => {
        switch (formType) {
            case 'NEW':
                return 'Create'
            case 'VIEW':
                return 'View'
            case 'EDIT':
                return 'Update'
            case 'DELETE':
                return 'Delete'
        }
    }

    const onChangeInput = (value: any, key: string) => {
        if (key === 'buildings') value = value.slice(0, auth.max_buildings)

        if (key === 'cores') value = value.slice(0, auth.max_cores)
        let newKey = key
        if (key.indexOf('/') !== -1) {
            let keys = key.split('/')
            value = { ...data[keys[0]], [keys[1]]: value }
            newKey = keys[0]
        }

        setData((prev: any) => ({ ...prev, [newKey]: value }))
        clearError(key)
    }

    const clearError = (key: string) => {
        let value = false
        if (key.indexOf('/') !== -1) {
            let keys = key.split('/')
            value = { ...errors[keys[0]], [keys[1]]: false }
            key = keys[0]
        }

        setErrors((prev: any) => ({ ...prev, [key]: value }))
        setBanners({ id: null })
    }

    //form actions
    const onClose = () => {
        setSponsoredAccountForm({
            show: false,
            data: {},
        })
    }

    const delayClose = () => {
        setTimeout(clearForm, 1000)
    }

    const clearForm = () => {
        setBanners({ id: null })
        setErrors({})
        onClose()
    }

    const onValidateForm = (delayClose: boolean) => {
        let body = CloneDeep(data),
            errors: any = {
                person: {},
            }

        console.log(body)

        if (!body.sponsor) errors['sponsor'] = true

        if (!body.department) errors['department'] = true

        if (!body.officeAddress) errors['officeAddress'] = true

        if (!body.costObject || body.costObject.toString().length !== 7) errors['costObject'] = true

        if (!body.startDate) errors['startDate'] = true

        if (!body.reasonForAccess) errors['reasonForAccess'] = true

        if (body.existingMitid && (!body.person.id || body.person.id.length !== 9)) errors.person['id'] = true

        if (!body.person.first_name) errors.person['first_name'] = true

        if (!body.person.last_name) errors.person['last_name'] = true

        if (!body.person.dob) errors.person['dob'] = true

        if (!body.person.organization) errors.person['organization'] = true

        if (!body.person.email) errors.person['email'] = true

        if (!body.person.phone || body.person.phone.length !== 10) errors.person['phone'] = true

        if (!body.person.address1) errors.person['address1'] = true

        if (!body.person.city) errors.person['city'] = true

        if (!body.person.state) errors.person['state'] = true

        if (!body.person.postalCode || body.person.postalCode.length !== 5) errors.person['postalCode'] = true

        let hasErrors = Object.keys(errors).filter((key: string) => key !== 'person').length > 0 || Object.keys(errors.person).length > 0
        setErrors(errors)

        if (!hasErrors) {
            onSubmit(body, delayClose)
        }
    }

    const onSubmit = (body: any, bClose: boolean) => {
        console.log('body', body)
        setBanners({ id: null })
        setIsLoading(true)
        if (formType === 'NEW') {
            controller
                .createAccount(body)
                .then((data) => {
                    setBanners({ id: 1, header: 'Success', text: `Sponsored Account was successfully created`, type: 'success', messages: [] })
                    setIsLoading(false)
                    setRefreshAllocation((prev: boolean) => !prev)
                    if (bClose) {
                        delayClose()
                    }
                })
                .catch((error) => {
                    setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                    setIsLoading(false)
                })
        } else if (formType === 'EDIT') {
            controller
                .updateAccount(body.id, body)
                .then((data) => {
                    setBanners({ id: 1, header: 'Success', text: `Sponsored Account was successfully updated`, type: 'success', messages: [] })
                    setIsLoading(false)
                    setRefreshAllocation((prev: boolean) => !prev)
                    if (bClose) {
                        delayClose()
                    }
                })
                .catch((error) => {
                    setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                    setIsLoading(false)
                })
        } else if (formType === 'DELETE') {
            controller
                .deleteAccount(body.id)
                .then((data) => {
                    setBanners({ id: 1, header: 'Success', text: `Sponsored Account was successfully deleted`, type: 'success', messages: [] })
                    setIsLoading(false)
                    setRefreshAllocation((prev: boolean) => !prev)
                    if (bClose) {
                        delayClose()
                    }
                })
                .catch((error) => {
                    setBanners({ id: 1, header: 'Error', text: error, type: 'danger', messages: [] })
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        let data = CloneDeep(sponsoredAccountForm.data)
        setData(data)
    }, [sponsoredAccountForm.data])

    return (
        <Drawer
            type={DrawerType.Right}
            size={DrawerSize.Large}
            themedColor='medical'
            show={sponsoredAccountForm.show}
            // show={true}
            header={<h3>{getFormTypeText()} Sponsored Account</h3>}
            footer={[]}
            onClose={clearForm}
            contents={
                <>
                    <Loading loaded={isLoading} />
                    <Banner banners={banners} setBanners={setBanners} />
                    <form className='allocation-form'>
                        <GridContainer showGutters={true}>
                            <LayoutColumn colSize={6}>
                                <SponsorInformation
                                    data={data}
                                    errors={errors}
                                    formType={formType}
                                    onChangeInput={onChangeInput}
                                    lookups={useLookupsContext()}
                                />

                                <AccessInformation data={data} errors={errors} formType={formType} onChangeInput={onChangeInput} />
                            </LayoutColumn>
                            <LayoutColumn colSize={6}>
                                <PersonalInformation
                                    data={data}
                                    errors={errors}
                                    formType={formType}
                                    onChangeInput={onChangeInput}
                                    lookups={useLookupsContext()}
                                />

                                <Address
                                    data={data}
                                    errors={errors}
                                    formType={formType}
                                    onChangeInput={onChangeInput}
                                    lookups={useLookupsContext()}
                                />
                            </LayoutColumn>
                            {formType !== 'VIEW' && (
                                <LayoutColumn colSize={12}>
                                    <Button
                                        type={ButtonType.Primary}
                                        text={`${getFormTypeText()} and stay`}
                                        onClick={onValidateForm}
                                        state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                    />
                                    &nbsp;&nbsp;
                                    <Button
                                        type={ButtonType.Ghost | ButtonType.Primary}
                                        text={`${getFormTypeText()} and close`}
                                        onClick={() => onValidateForm(true)}
                                        state={isLoading ? ButtonState.Disabled : ButtonState.Enabled}
                                    />
                                </LayoutColumn>
                            )}
                        </GridContainer>

                        {formType !== 'NEW' && (
                            <LayoutColumn colSize={12}>
                                <div className='mt-5'>Updated By: {data.updated_by}</div>
                                <div>Updated On: {getDateTimeString(data.updated_on, true)}</div>
                            </LayoutColumn>
                        )}
                    </form>
                </>
            }
        />
    )
}
